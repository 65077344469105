import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createRequest } from "../../utils/common";
import NotificationsCard from "./notificationsCard";
import { Loader } from "../Loader";

const Notifications = () => {
  const [notification, setNotification] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limitStatus, setLimitStatus] = useState(false);

  const GetNotification = async () => {
    const data = await createRequest(`/api/v1/resellers/notifications`, {
      method: "POST",
      body: page,
    });
    setLoading(true);
    if (data?.header?.status === "success") {
      setNotification(data.body);
      var aa = notificationData;
      setNotificationData(aa.concat(...data.body.data));
    } else {
      toast.error(
        data?.header?.message ||
          "code : rspf-003, Unable to fetch Notifications"
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    GetNotification();
  }, [page]);

  const Loadmore = () => {
    if (page < notification?.last_page) {
      setPage(page + 1);
    }
    if (page === notification?.last_page) {
      setLimitStatus(true);
    }
  };

  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      Loadmore();
    }
  };

  return (
    <>
      <div className="dashboard-banner notify-head">
        <div className="notification-head-wrap">
          <h3 className="noti-menu mb-4 d-flex">Notifications</h3>
          <div className="notification-menu">
            <p>All Notification |</p>

            {/* <div onClick={() => ReadNotification()}>
              <p>Mark all as read</p>
            </div> */}
          </div>
        </div>
        <div className="dashboard-wrapper">
          <div>
            {notificationData.map((notifications) => (
              <>
                <NotificationsCard
                  readAt={notifications.read_at}
                  message={JSON.parse(notifications.data).message}
                  typename={JSON.parse(notifications.data).type}
                  date={JSON.parse(notifications.data).created_at}
                  redirect={JSON.parse(notifications.data).redirect}
                  Id={JSON.parse(notifications.data).Id}
                  notificationid={notifications.uid}
                />
              </>
            ))}
          </div>
        </div>
        <div>
          {limitStatus ? (
            <p>No Notification</p>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notifications;
