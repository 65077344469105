export const Loader = () => {
    return(
        <div className="loader-wrapper">
            <div className="site-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}