//React packages
import React, { useState, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";

//React components
import MobileNavbar from "../layout/MobileNavbar";
import Navbar from "../layout/Navbar";
import FirstLogin from "./FirstLogin";
import Dashboard from "./Dashboard";
import Profile from "./../profile/Profile";

import SuccessPayment from "./payments/SuccessPayment";

//React layout
import Head from "../layout/Head";



import Header from "./Header";
import GenerateCoupons from "../coupons/GenerateCoupons";

//admin
import AllUser from "../admin/users";
import AdminListCoupon from "../admin/AdminCouponList";
import AdminListVPN from "../admin/AdminVPNList";
import AdminGenerateCoupons from "../admin/AdminGenerateCoupons";

import CouponPrice from "../coupons/CouponPrice";
import CouponList from "../coupons/CouponList";
import Subscriptions from "../vpn/Subscriptions";
import List from "../vpn/List";
import { AddFunds } from "../billing-invoices/AddFunds";
import { PayInvoice } from "../billing-invoices/PayInvoice";
import Invoices from "../billing-invoices/Invoices";
import UnpaidInvoices from "../billing-invoices/UnpaidInvoices";
import Transactions from "../billing-invoices/TransactionHistory";
import { useSelector, useDispatch } from "react-redux";
import { doLogout } from "../../redux/actions/auth";
import DetailedInvoice from "../billing-invoices/DetailedInvoice";
import UserEdit from "../admin/useredit";
import ExploreDocs from "./ExploreDocs";
import GenerateApi from "../generate/generateApi";
import GenerateForm from "../generate/generateForm";
import Redeem from "../auth/Redeem";
import Notifications from "../notifications/notifications";

const Index = (props) => {
  //Flag for paid user login check
  //const [isPaid, setIsPaid] = useState(false);
  const dispatch = useDispatch();

  //Loading bar
  const [loadingState, setLoadingState] = useState(false);

  try {
    var user_name = useSelector((state) => state?.auth?.user?.custom_url);
  } catch (error) {
    dispatch(doLogout());
  }

  return (
    <Fragment>
      <MobileNavbar props={props} />
      <div className={"dashboard-wrapper "}>
        <Head title={"PureVPN Reseller | Dashboard"} />
        <ToastContainer />
        {
          <Fragment>
            <Navbar props={props} />
            <Header props={props} />
            <div className="dashboard-body">
              {loadingState ? (
                <div className="dashboard-loader">
                  <Loader
                    type="Puff"
                    color="#2399f1"
                    height={80}
                    width={80}
                    visible={true}
                  />
                </div>
              ) : (
                ""
              )}
              <Switch>
                {/* {
                                    !isPaid
                                        ? <Route path={`/${user_name}/dashboard/overview`} exact component={FirstLogin} />
                                        : <Route path={`/${user_name}/dashboard/overview`} exact component={ () => <Dashboard toast={toast}/>} />
                                } */}

                {/*Dashboard*/}
                <Route
                  path={"/dashboard"}
                  exact
                  component={() => {
                    return <Redirect to={`/${user_name}/dashboard/overview`} />;
                  }}
                />
                <Route
                  path={`/${user_name}/dashboard/overview`}
                  exact
                  component={() => <Dashboard toast={toast} />}
                />
                <Route
                  path={`/${user_name}/users/all`}
                  exact
                  component={() => <AllUser toast={toast} />}
                />
                <Route
                  path={`/${user_name}/user/edit/:id`}
                  exact
                  component={() => <UserEdit toast={toast} />}
                />
                <Route
                  path={`/${user_name}/user/edit/:id/listcoupons`}
                  exact
                  component={() => <AdminListCoupon toast={toast} />}
                />
                <Route
                  path={`/${user_name}/user/edit/:id/listsusbscriptions`}
                  exact
                  component={() => <AdminListVPN toast={toast} />}
                />
                <Route
                  path={`/${user_name}/user/edit/:id/generatecoupon`}
                  exact
                  component={() => <AdminGenerateCoupons toast={toast} />}
                />
                {/*Coupons*/}
                <Route
                  path={"/coupons"}
                  exact
                  component={() => {
                    return <Redirect to={`/${user_name}/coupons/generate`} />;
                  }}
                />
                <Route
                  path={`/${user_name}/coupons/generate`}
                  exact
                  component={() => (
                    <GenerateCoupons history={props.history} toast={toast} />
                  )}
                />
                <Route
                  path={`/${user_name}/coupons/list`}
                  exact
                  component={() => <CouponList toast={toast} />}
                />
                <Route
                  path={`/${user_name}/coupons/price`}
                  exact
                  component={() => <CouponPrice toast={toast} />}
                />

                {/*VPN*/}
                <Route
                  path={"/vpn"}
                  exact
                  component={() => {
                    return <Redirect to={`/${user_name}/vpn/subscriptions`} />;
                  }}
                />
                <Route
                  path={`/${user_name}/vpn/subscriptions`}
                  exact
                  component={() => <Subscriptions toast={toast} />}
                />
                <Route
                  path={`/${user_name}/vpn/subscriptions/create`}
                  exact
                  component={() => <Subscriptions toast={toast} />}
                />
                <Route
                  path={`/${user_name}/vpn/subscriptions/update`}
                  exact
                  component={() => <Subscriptions toast={toast} />}
                />
                <Route
                  path={`/${user_name}/vpn/subscriptions/enable`}
                  exact
                  component={() => <Subscriptions toast={toast} />}
                />
                <Route
                  path={`/${user_name}/vpn/subscriptions/delete`}
                  exact
                  component={() => <Subscriptions toast={toast} />}
                />
                <Route
                  path={`/${user_name}/vpn/subscriptions/list`}
                  exact
                  component={() => <List toast={toast} />}
                />

                {/*Billing & History*/}
                <Route
                  path={"/billing"}
                  exact
                  component={() => (
                    <Redirect to={`/${user_name}/billing/pay`} />
                  )}
                />
                <Route
                  path={`/${user_name}/billing/pay`}
                  exact
                  component={() => <AddFunds toast={toast} />}
                />
                <Route
                  path={`/${user_name}/billing/pay/invoice/:id`}
                  exact
                  component={() => <PayInvoice toast={toast} />}
                />
                <Route
                  path={`/${user_name}/billing/invoices`}
                  exact
                  component={() => <Invoices toast={toast} />}
                />
                <Route
                  path={`/${user_name}/billing/unpaid/invoices`}
                  exact
                  component={() => <UnpaidInvoices toast={toast} />}
                />
                <Route
                  path={`/${user_name}/billing/transactions`}
                  exact
                  component={() => <Transactions toast={toast} />}
                />
                <Route
                  path={`/${user_name}/billing/invoice/:id`}
                  exact
                  component={() => <DetailedInvoice toast={toast} />}
                />
                <Route
                  path={`/${user_name}/dashboard/exploredocs`}
                  exact
                  component={() => <ExploreDocs toast={toast} />}
                />
                <Route
                  path={`/${user_name}/integration/form`}
                  exact
                  component={() => <GenerateForm toast={toast} />}
                />
                <Route
                  path={`/${user_name}/dashboard/generateApi`}
                  exact
                  component={() => <GenerateApi toast={toast} />}
                />
                <Route
                  path={`/${user_name}/notifications`}
                  exact
                  component={() => <Notifications toast={toast} />}
                />
                <Route
                  path={`/${user_name}/dashboard/redeem`}
                  exact
                  component={() => <Redeem toast={toast} />}
                />

                {/* Profile */}
                <Route
                  path={`/${user_name}/profile`}
                  exact
                  component={() => <Profile toast={toast} />}
                />

                {/*Coupons*/}
                {/* <Route path={'/vpn/subscriptions'} exact component={GenerateCoupons} />
                                <Route path={'/vpn/list'} exact component={GenerateCoupons} /> */}

                {/*Payments*/}
                <Route
                  path={`/${user_name}/payments/success`}
                  exact
                  component={SuccessPayment}
                />

                {/*Settings*/}
                {/* <Route path={'/settings/profile'} exact component={Profile} /> */}
                <Route
                  component={() => {
                    return <Redirect to={"/dashboard"} />;
                  }}
                />
              </Switch>
            </div>
          </Fragment>
        }
      </div>
    </Fragment>
  );
};

export default Index;
