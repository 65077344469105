import React, {useRef, useState, useEffect} from 'react';

export const Tooltip = ({children}) => {

    const [showtt, setShowtt] = useState(0);
    const tip = useRef();
    const ttContent = useRef(null);

    const styles = {
        show: {
            opacity: 1,
            zIndex: 99
        },
        hide: {
            opacity: 0,
            zIndex: -1
        }
    }
    const showtooltip = (e) => {
        ttContent.current.style.bottom = "unset";
        ttContent.current.style.top = -Math.abs((ttContent.current.offsetHeight / 2) - 10) + 'px';
        setShowtt(showtt ? 0 : 1);
    }

    const handleClick = ({target}) => {
        if (target?.current?.contains(tip)) {
            return;
        }
        setShowtt(0);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        document.body.classList.add('tt_bound');

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <div className="Tooltip-component">
            <div className="tt-container">
                <div className={`tt-icon ` + (showtt===0?'':'open')} onClick={showtooltip} ref={tip}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </div>
                <div className="tt-content" ref={ttContent} style={showtt===0?styles.hide:styles.show}>{children}</div>
            </div>
        </div>
    )
}