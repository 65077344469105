import React from 'react';

const FAQ = ({props}) => {
    return(
        <div className="w-box faq-box mt-5 mb-4">
            <h2 className="component-heading no-border">Frequently Asked Questions (FAQs)</h2>
            {props.map((faq, i) => {
                return <details key={i}>
                    <summary>{faq.q}</summary>
                    <span className="content">
                        <ul>
                            <li>{faq.a}</li>
                        </ul>
                    </span>
                </details>
            })}
        </div>
    )
}

export default FAQ;