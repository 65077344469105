import React, { Fragment, useRef, useState } from 'react';
import { CustomDivTable } from '../CustomDivTable';
import { Loader } from '../Loader';
import { SearchVPNAccount } from './SearchVPNAccount';
import {createRequest} from '../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faBan, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

export const EnableDisable = ({props, toast}) => {

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const childRef = useRef();
    const MySwal = withReactContent(Swal);

    const doRequest = async (params) => {
        
        setLoading(true);
    
        try {
            
            const { username, status } = params
            let url = status === 'delete' ? '/api/v1/resellers/vpn/delete' : '/api/v1/resellers/vpn/update/status'
            setShowSearchResult(false);
console.log('acount delted');
            const data = await createRequest(url, { username:username, status:status });

            if(data.header.status === 'success'){
                
                setTimeout(() => {
                    toast.success(data?.header?.message);
                    childRef.current.callSearch();
                    setShowSearchResult(true);
                    setLoading(false);
                }, 600);

            }else{

                setLoading(false);
                throw new Error(data.header.message);

            }
            
        } catch (error) {

            setShowSearchResult(true);
            toast.error(error.message);
            setLoading(false);
            
        }
  
    }

    const ActiveMethod = async (e) => {
        var pEle = e.target.parentElement.parentElement;
        if (e.target.nodeName !== 'BUTTON') {
            pEle = e.target.parentElement.parentElement.parentElement;
        }
        if (e.target.nodeName === 'path') {
            pEle = e.target.parentElement.parentElement.parentElement.parentElement;
        }

        let username = Array.from(pEle.children).filter(v => v.dataset.column === "vpn_username")[0].textContent;
        await doRequest({username, status:'enable'});

    }

    const DisabledMethod = async (e) => {
        var pEle = e.target.parentElement.parentElement;
        if (e.target.nodeName !== 'BUTTON') {
            pEle = e.target.parentElement.parentElement.parentElement;
        }
        if (e.target.nodeName === 'path') {
            pEle = e.target.parentElement.parentElement.parentElement.parentElement;
        }

        let username = Array.from(pEle.children).filter(v => v.dataset.column === "vpn_username")[0].textContent;
        await doRequest({username, status:'disable'});

    }

    const DeleteMethod = async (e) => {
        var pEle = e.target.parentElement.parentElement;
        if (e.target.nodeName !== 'BUTTON') {
            pEle = e.target.parentElement.parentElement.parentElement;
        }
        if (e.target.nodeName === 'path') {
            pEle = e.target.parentElement.parentElement.parentElement.parentElement;
        }

        //swal confirm delete
        let username = Array.from(pEle.children).filter(v => v.dataset.column === "vpn_username")[0].textContent;
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'delete it!'
        }).then(async(willDelete) => {
          if (willDelete.isConfirmed) {
                await doRequest({username, status:'delete'});
            }
        });
    }

    const showResults = (data) => {
        setTableData(data);
        if(data.length) {
            setShowSearchResult(true);
        }else{
            setShowSearchResult(false);
        }
    }

    return(
        <Fragment>
            <h2 className="component-heading">{props?.component === 'delete' ? 'Delete VPN Account' : 'Enable / Disable VPN Account'}</h2>

            <SearchVPNAccount
                setData={showResults}
                toast={toast}
                setLoading={setLoading}
                setShowSearchResult={setShowSearchResult}
                ref={childRef}
                />

            {loading ? <Loader /> : ''}

            {showSearchResult ?
                props?.component === 'delete' ?
                    <CustomDivTable 
                        props={tableData} 
                        id="enable-vpn-account" 
                        classes={["mt-5"]} 
                        settings={{
                            actionColumn: {
                                enable: true,
                                rules: {
                                    hosting_status: {
                                        conditions: {
                                            active: <button onClick={DeleteMethod} className="ct_btn" title="Delete">{<FontAwesomeIcon icon={faTrash} />}</button>,
                                            suspended: <button onClick={DeleteMethod} className="ct_btn" title="Delete">{<FontAwesomeIcon icon={faTrash} />}</button>
                                        }
                                    }
                                }
                            },
                            row_identifier: 'hosting_id',
                        }} />
                    :
                    <CustomDivTable 
                        props={tableData} 
                        id="enable-vpn-account" 
                        classes={["mt-5"]} 
                        settings={{
                            actionColumn: {
                                enable: true,
                                rules: {
                                    hosting_status: {
                                        conditions: {
                                            suspended: <button onClick={ActiveMethod} className="ct_btn" title="Activate">{<FontAwesomeIcon icon={faCheckDouble} />}</button>,
                                            active: <button onClick={DisabledMethod} className="ct_btn" title="Deactivate">{<FontAwesomeIcon icon={faBan} />}</button>
                                        }
                                    }
                                }
                            },
                            row_identifier: 'hosting_id',
                        }} /> 
            :''}

        </Fragment>
    )
}