//React packages
import React,{useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

//Rest Api service
import { RegisterService } from "../../services/Register";

//React layout
import Head from "../layout/Head";
import LoadingBar from "../layout/LoadingBar";
import Loader from "../layout/Loader";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import logo from '../../assets/img/purevpn-logo-black.png'
import { checkAuth, hasCsrf, emailValidation, validatePassword } from '../../utils/common'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const MySwal = withReactContent(Swal);

const Register = (props) => {

    // Loading bar state
    const [loading, setLoading] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    
    // Will execute after fully loaded
    useEffect(() => {
        setLoading(100);
        hasCsrf();
    }, []);

    // state for form values and handling error
    const [state, setState] = useState({
        email: '',
        password: '',
        name: '',
        password_confirmation: '',
        loading: false,
        error: '',
        emailError: false,
        passwordError: false,
        nameError: false,
        confirmPasswordError: false,
        visible: true,
        captchaToken:''
    });

    // Handling form values and saving it into state
    const handleChange = (e) => {
        setState({
            ...state, [e.target.name]: e.target.value
        });
    };

    // Login Form Submit
    const submit = async (e) => {
        
        e.preventDefault();

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            return
        }

        if(!validateForm()) return false;

        setState({ ...state,
            emailError: false,
            passwordError: false,
            nameError: false,
            confirmPasswordError: false,
            loading: true
        });

        const token = await executeRecaptcha('register');

        RegisterService(state, token).then(res => {

            if(res.header.code === 20001 && res.header.status === "success"){
                MySwal.fire({
                    title: "<h3>" + res.header.message + "<h3>",
                    icon:'success',
                    text:'Please click on the link that has just been sent to your email account to verify your email.'
                });
                setState({...state, loading:false, password:'', password_confirmation:'', email:'', name:''});

            }else{
                setState({...state, loading:false, error: res.header.message, password:'', password_confirmation:''});
                MySwal.fire({
                    title: res.header.message,
                    icon:'error',
                    onClose: () => checkAuth(res, 'register')
                });
            }
        }).catch((err) => {
            setState({...state, loading:false, error: err, visible: true, password:''});
            toast.error(err.message)
        });
    };

    const validateForm = () => {

        const { email, name, password, password_confirmation } = state;

        if(email === ''){
            setState({ ...state, emailError:true});
            return false;
        }

        if(name === ''){
            setState({ ...state, nameError:true });
            return false;
        }

        if(password === ''){
            setState({ ...state, passwordError:true });
            return false;
        }

        if(password !== password_confirmation){
            setState({ ...state, confirmPasswordError:true });
            return false;
        }

        try{

            validatePassword(password);

        }catch (error) {

            toast.error(error.message);
            return false;
            
        }

        return true;
    }

    // Password validation on blur
    const passValidate = (e) => {
        e.target.value === '' ? setState({...state, passwordError:true}) : setState({...state, passwordError:false});
    };

    // Email validation on blur
    const emailValidate = (e) => {
        if(e.target.value === ''){
            setState({...state, emailError:true})
        }else{
            if(emailValidation(e.target.value)){ setState({ ...state, emailError:false }); }
            else{ setState({ ...state, emailError:true }); }
        }
    };
    
    // name validation on blur
    const nameValidate = (e) => {
        if(e.target.value !== ''){
            setState({...state, nameError:false})
        }
    };

    return(
        <div>
            <Head title={'PureVPN Reseller | Signup'}/>
            <LoadingBar loading={loading}/>
            <ToastContainer />
            {
                state.loading ? <Loader loading={loading}/> : ''
            }
            <div className="accountMain hasBackground">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-3">
                            <div className="accountParent">
                                <div className="accountBox">
                                    <div className="accountHead">
                                        <img src={logo} className="img-responsive" alt="" />
                                        <h3>Sign up to PureVPN Reseller</h3>
                                    </div>
                                    <form className="reset-form" onSubmit={submit} data-id="1" noValidate>
                                        <div className="accountBody">
                                            <div className="formBox" id="emailBox">
                                                <div className="form-group">
                                                    <input type="email"
                                                           onChange={handleChange}
                                                           className="form-control"
                                                           placeholder="Email address"
                                                           name="email"
                                                           maxLength="50"
                                                           onBlur={emailValidate}
                                                           value={state.email}
                                                    />
                                                    <span className={"errorText " + (state.emailError ? 'show' : '')}>Email address is required</span>
                                                </div>
                                            </div>
                                            <div className="formBox" id="nameBox">
                                                <div className="form-group">
                                                    <input type="text"
                                                           onChange={handleChange}
                                                           className="form-control"
                                                           placeholder="Full Name"
                                                           name="name"
                                                           onBlur={nameValidate}
                                                           value={state.name}
                                                    />
                                                    <span className={"errorText " + (state.nameError ? 'show' : '')}>Full Name is required</span>
                                                </div>
                                            </div>
                                            <div className="formBox" id="passwordBox">
                                                <div className="form-group">
                                                    <input type={showPassword ? "text" : "password" }
                                                           onChange={handleChange}
                                                           className="form-control"
                                                           placeholder="Password"
                                                           name="password"
                                                           onBlur={passValidate}
                                                           value={state.password}
                                                    />
                                                    <FontAwesomeIcon style={{cursor: 'pointer', float: 'right', position: 'relative', marginTop: '-30px', marginRight : '8px'}} icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)}/>
                                                    <span className={"errorText " + (state.passwordError ? 'show' : '')}>Password is required</span>
                                                </div>
                                            </div>
                                            <div className="formBox" id="confirmPasswordBox">
                                                <div className="form-group">
                                                    <input type={showPassword ? "text" : "password" }
                                                           onChange={handleChange}
                                                           className="form-control"
                                                           placeholder="Confirm Password"
                                                           name="password_confirmation"
                                                           value={state.password_confirmation}
                                                    />
                                                    <span className={"errorText " + (state.confirmPasswordError ? 'show' : '')}>Passwords don't match</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accountFooter">
                                            <div className="fullwidthbutton text-center">
                                                <button type="submit" className="theme-gradient-btn" id="accountRegiter">Register</button>
                                                <p>Already have an account? <Link to={'login'}>Log in?</Link> </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Register
