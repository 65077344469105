import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useEffectOnce } from 'react-use';
import {createRequest} from '../../utils/common';

export const SearchVPNAccount = forwardRef(({setData, toast, setLoading, setShowSearchResult}, ref) => {
    const [filterParams, setSearchParams] = useState({
        key: '',
        value: ''
    });

    const search = async () => {

        if (Object.values(filterParams).join('') !== '') {
            window.localStorage.setItem('searchParams', JSON.stringify(filterParams))
        }
        
        const fParams = Object.values(filterParams).join('') === '' ? JSON.parse(window.localStorage.getItem('searchParams')) : filterParams;

        if(fParams.key === '' || fParams.value === '') {
            toast.error('Please select search type or insert details');
            return;
        }

        try {

            setLoading(true);
            setShowSearchResult(false);

            const data = await createRequest(`/api/v1/resellers/vpn/search`, { search: fParams.key, value: fParams.value });

            if(data.header.status === 'success') {
                setData(data?.body?.account_details);
            }
            else {
                throw new Error(data.header.message);
            }
            
        } catch (error) {
            setData([]);
            toast.error(error.message);
        }
        
        setLoading(false);
    }

    useImperativeHandle(ref, () => ({
        callSearch() {
            search();
        }
    }));

    useEffect(() => {
        window.localStorage.setItem('searchParams', JSON.stringify(filterParams));
    })

    useEffectOnce(() => {
        window.localStorage.setItem('searchParams', JSON.stringify({key: '', value: ''}));
    })

    return(
        <form className="search-vpn-account">
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="product">Search VPN Account:</label>
                        <div className="custom_search_field">
                            <input type="text" value={filterParams.value} onChange={(e) => setSearchParams({...filterParams, value: e.target.value})} />
                            <select onChange={(e) => setSearchParams({...filterParams, key: e.target.value})}>
                                <option selected={filterParams.key === '' ? 'selected' : '' } value="" >Search By:</option>
                                <option selected={filterParams.key === 'email' ? 'selected' : '' } value="email">Email</option>
                                <option selected={filterParams.key === 'coupon' ? 'selected' : '' } value="coupon">Coupon Code</option>
                                <option selected={filterParams.key === 'username' ? 'selected' : '' } value="username">Username</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="form-group mb-0">
                        <button type="button" className="theme-gradient-btn" id="search-btn" onClick={search}>Fetch VPN Details</button>
                    </div>
                </div>
            </div>
        </form>
    )
})