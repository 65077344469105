import React, { Fragment, useEffect, useState, useRef } from "react";
import { createRequest } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { CustomDivTable } from "../CustomDivTable";
import { Loader } from "../Loader";
import { CSVLink } from "react-csv";
import { updateProfile } from "../../redux/actions/auth";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faTrash,
  faTimes,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";

const ProductPricing = ({ toast }) => {

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToExport, setDataToExport] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [couponId, setCouponId] = useState("");
  const couponEmail = useRef(null);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [filterType, setFilterType] = useState("code"); //code, date, billingType
  const [reload, setReload] = useState(null);
  const [filterData, setFilterData] = useState({
    code: "",
    dateStart: "",
    dateEnd: "",
    billingType: "",
  });
  const csvLinkRef = useRef();
  const [priceState, setState] = useState({
    onetime_price: {},
    recurring_price: {},
  });

  const billingCycleNames = {
    "monthly": "Monthly Plan (1 Month)",
    "quarterly": "Quaterly Plan (3 Month)",
    "semiannually": "Semiannually Plan (6 Month)",
    "annually": "Annually Plan (12 Month)",
    "biennially": "Biennially Plan (2 Years)",
    "triennially": "Triennially Plan (3 Years)"
  };
  
  const GetPricingList = async () => {
    setLoading(true);

    try {
      let filterPayload = { ...filterData };
      const data = await createRequest("/api/v1/resellers/products/pricing");
      if (data?.header?.status === "success") {
        console.log(data);
        setState({
          ...priceState,

          onetime_price: data?.body?.onetime?.product,
          recurring_price: data?.body?.recurring?.product,
        });
      } else {
        throw new Error(data?.header?.message);
      }
    } catch (error) {
      toast.error(
        error?.message || "code : rspf-003, Unable to fetch coupon list"
      );
      setState({
        ...priceState,
        onetime_price: {},
        recurring_price: {},
      });
    } finally {
      setLoading(false);
      setReload(false)
    }


  };


  useEffect(() => {
    GetPricingList();
  }, []);



  return (
    <>
      <h2 className="component-heading">Product Pricing</h2>
      <p className="heading-slogan">
        Following are the pricing for PureVPN products for you based on  product type and billing cycle.
      </p>
      {loading ? (
        <Loader />
      ) :
      <div className="pdfCanvas">
        <div className="cs-box minHeight">
          <div className="row">
            <div className="col">
              <h5>
                <span>
                  RECURSIVE
                </span>
              </h5>
              <br></br>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Billing Cycle</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(priceState.recurring_price || {}).map((key) => {
                    if (billingCycleNames[key]) {
                      return (<tr className="table-active">
                        <td scope="row">{billingCycleNames[key]}</td>
                        <td>{priceState.recurring_price[key].price}</td>
                      </tr>)
                    }
                  })}
                </tbody>
              </table>
            </div>
            <div className="col">
              <h5>
                <span>
                  NON-RECURSIVE
                </span>
              </h5>
              <br></br>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Billing Cycle</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(priceState.onetime_price || {}).map((key) => {
                    if (billingCycleNames[key]) {
                      return (<tr className="table-active">
                        <td scope="row">{billingCycleNames[key]}</td>
                        <td>{priceState.onetime_price[key].price}</td>
                      </tr>)
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default ProductPricing;