//React packages
import React,{useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';

//Rest Api service
import { doLogin } from "../../services/Login";
import { loginSuccess } from "../../redux/actions/auth";

//React layout
import Head from "../layout/Head";
import LoadingBar from "../layout/LoadingBar";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import logo from '../../assets/img/purevpn-logo-black.png'
import { checkAuth, hasCsrf, emailValidation, validatePassword } from '../../utils/common'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const MySwal = withReactContent(Swal);

const Login = (props) => {
    
    // Loading bar state
    const [loading, setLoading] = useState(0);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();

    // Will execute after fully loaded
    useEffect(() => {
        setLoading(100)
        hasCsrf();
    }, []);

    // state for form values and handling error
    const [state, setState] = useState({
        email: '',
        password: '',
        loading: false,
        error: '',
        emailError: false,
        passwordError: false,
        showEmail: true,
        showPassword: false,
        visible: true
    });

    // Flag of switch expression
    const [flag, setFlag] = useState(1);

    // Handling form values and saving it into state
    const handleChange = (e) => {
        setState({
            ...state, [e.target.name]: e.target.value
        });
    };

    // Login Form Submit
    const submit = async (e) => {
        e.preventDefault();
        switch (flag){
            case 1:
                if(state.email === ''){
                    setState({ ...state, emailError:true });
                }else{
                    if(emailValidation(state.email)){
                        // email validation success
                        setState({ ...state, emailError:false, showEmail:false, showPassword:true });
                        setFlag(2);
                    }else{
                        setState({
                            ...state, emailError:true
                        });
                    }
                }
                break;
            case 2:
                if(state.password === ''){
                    setState({ ...state, emailError:false, passwordError:true });
                }else{

                    if (!executeRecaptcha) {
                        return
                    }
                    
                    setState({ ...state, passwordError:false, loading: true});
                    const token = await executeRecaptcha('login');

                    doLogin(state, token).then(res => {
                        if(res.header.code === 10001 && res.header.status === "success"){
                            
                            dispatch(
                                loginSuccess(res)
                            ).catch((err) => {
                                toast.error(err.message)
                            });

                            props.history.push(`/dashboard`);

                        }else{

                            setState({...state, loading:false, error: res.header.message, password:''});
                            MySwal.fire({
                                title: res.header.message,
                                icon:'error',
                                onClose: () => checkAuth(res)
                            });
                        }
                    }).catch((err) => {

                        setState({...state, loading:false, error: err, visible: true, password:''});
                        toast.error(err.message)

                    });
                }
                break;
            default: return flag;
        }
    };

    // Go back to email box from password box
    const showEmail = () => {
        setState({...state, showEmail:true, showPassword:false, password:''});
        setFlag(1);
    };

    // Password validation on blur
    const passValidate = (e) => {
        e.target.value === '' ? setState({...state, passwordError:true}) : setState({...state, passwordError:false});
    };

    // Email validation on blur
    const emailValidate = (e) => {
        if(e.target.value === ''){
            setState({...state, emailError:true})
        }else{
            if(emailValidation(e.target.value)){ setState({ ...state, emailError:false }); }
            else{ setState({ ...state, emailError:true }); }
        }
    };

    return(
        <div>
            <Head title={'PureVPN Reseller | Login'}/>
            <LoadingBar loading={loading}/>
            <ToastContainer />
            {
                state.loading
                    ?
                    <div className={'dashboard-inner-loader ml-0 ' + (loading ? 'show' : 'hide')}>
                        <div className="sk-chase">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>
                    </div>
                    :
                        ''
            }
            <div className="accountMain hasBackground">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-3">
                            <div className="accountParent">
                                <div className="accountBox">
                                    <div className="accountHead">
                                        <img src={logo} className="img-responsive" alt="" />
                                        <h3>Sign in to PureVPN Reseller</h3>
                                        <p>Enter your PureVPN Reseller credentials below</p>
                                    </div>
                                    <form onSubmit={submit} data-id="1" noValidate>
                                        <div className="accountBody">
                                            <div className={'formBox ' + (state.showEmail ? 'show-email' : 'hide-email')} id="emailBox">
                                                <div className="form-group">
                                                    <input type="email"
                                                           onChange={handleChange}
                                                           className="form-control"
                                                           placeholder="Email address"
                                                           name="email" data-id="1"
                                                           onBlur={emailValidate}
                                                           value={state.email}
                                                    />
                                                    <span className={"errorText " + (state.emailError ? 'show' : '')}>Email address is required</span>
                                                </div>
                                            </div>
                                            <div className={'formBox ' + (state.showPassword ? 'show-password' : 'hide-password')} id="passwordBox">
                                                <div className="accountShower" onClick={showEmail}>
                                                    <h4><i className="fa fa-user-circle-o"> </i> <span>{state.email}</span></h4>
                                                </div>
                                                <div className="form-group">
                                                    <input type={showPassword ? "text" : "password" }
                                                           onChange={handleChange}
                                                           className="form-control"
                                                           placeholder="Password"
                                                           name="password"
                                                           onBlur={passValidate}
                                                           value={state.password}
                                                    />
                                                    <FontAwesomeIcon style={{cursor: 'pointer', float: 'right', position: 'relative', marginTop: '-30px', marginRight : '8px'}} icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)}/>
                                                    <span className={"errorText " + (state.passwordError ? 'show' : '')}>Password is required</span>
                                                </div>
                                            </div>
                                            <div className="mt-5">
                                                <Link to={'forgot-password'}>Forgot password?</Link>
                                                <p>If you don’t have an account? Click create account below.</p>
                                            </div>
                                        </div>
                                        <div className="accountFooter">
                                            <div className="pull-left">
                                                <Link to={'register'}>Create account</Link>
                                            </div>
                                            <div className="pull-right">
                                                <button type="submit" className="theme-gradient-btn" id="accountNext">Next</button>
                                            </div>
                                            <div className="clearfix"> </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login
