import Cookies from 'js-cookie';

export function RegisterVerficationService(hash, token){
    return new Promise((resolve,reject) => {
        fetch('/api/register/verify', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'CSRF-Token': Cookies.get('XSRF-TOKEN'),
                //'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3Rub3VtYW5sb2NhbEBnYWRpdGVrLmNvbSIsImlhdCI6MTYyODU5OTM2OSwiZXhwIjoxNjI4NjEwMTY5fQ.GJu6Kxwt_XkP_M3n8W96A8A6n0hXkkgy3C5hIwbKNHg',
            },
            body: JSON.stringify({hash, token}),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                resolve(responseJson);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
