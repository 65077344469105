import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { createRequest, capitalize } from '../../utils/common';
import { Loader } from '../Loader';
import purevpnlogo from '../../assets/img/PureVPN-logo.png';
import './media-print.css';
import AdminRoute from "../layout/PrivateNavLink";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


const DetailedInvoice = ({ toast }) => {

    const [hoveredBtnTitle, setHoveredBtnTitle] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [invoice, setInvoice] = useState({});

    const GetInvoice = async () => {
        let invoiceId = Number(id);
        setLoading(true);

        if (!isNaN(invoiceId)) {

            try {
                const data = await createRequest(`/api/v1/resellers/invoice`, { id: invoiceId });
                if (data?.header?.status === 'success') {
                    setInvoice(data?.body);
                }
                else {
                    throw new Error(data?.header?.message);
                }

            } catch (error) {
                toast.error(error?.message || "code : rspf-003, Unable to fetch invoices");
            }
        }

        setLoading(false);
    }

    const DownloadPDF = () => {
        const printEle = document.querySelector('.pdfCanvas');
        const btnDiv = document.querySelector('.action-btns');
        btnDiv.style.display = 'none';

        try {
            html2canvas(printEle).then(canvas => {
                btnDiv.style.display = 'block';
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', 'a4');
                pdf.addImage(imgData, 'PNG', 0, 0, 450, (printEle.clientHeight / 2));
                pdf.save('invoice.pdf');
            });
        } catch (error) {
            btnDiv.style.display = 'block';
            toast.error(error?.message || "code : rspf-003, Unable to create PDF");
        }
    }

    const PrintInvoice = () => {
        window.print();
    }

    useEffect(() => {

        GetInvoice();
    }, []);

    return (
        <>
            {loading ? <Loader /> :
                <div className="dashboard-invoice">
                    {Object.keys(invoice).length ?

                        <div className="pdfCanvas">

                            <h2 className="component-heading d-invoicehead">
                                <span className="head-line">Invoice # {id}</span>
                            </h2>
                            <div className="w-box minHeight">
                                <div className="Di-hearder">
                                    <div>
                                        <img src={purevpnlogo} alt="PureVPN" />
                                    </div>

                                    <div className="status-div">
                                        <p>Invoice Status</p>
                                        <h3 className={invoice.status.toLowerCase() === 'unpaid' ? 'status-unpaid' : ''}>{capitalize(invoice.status)}</h3>
                                        {invoice.status.toLowerCase() === 'paid' ?
                                            <p className="dpaid-p">Date Paid: {invoice.date}</p>
                                            : <AdminRoute to={`/billing/pay/invoice/${id}`} exact={true} >
                                                Pay Now
                                            </AdminRoute>
                                        }

                                    </div>
                                </div>

                                <div className="Divider"></div>

                                <div className="Di-hearder">
                                    <div className="i-detail">
                                        <p>Invoice to:</p>
                                        <p><b>{invoice.name}</b></p>
                                        <p>Invoice Date: {invoice.date}</p>
                                    </div>
                                    <div className="i-detail1">
                                        <p>Pay to:</p>
                                        <p><b>PureVPN</b></p>
                                        <p>Payment Method: {capitalize(invoice.payment_method)}</p>
                                    </div>
                                </div>

                                <div className="des-head">
                                    <span>Description</span>
                                    <span>Total</span>
                                </div>

                                <div className="des-inner">
                                    <div className="des-inner1">Wallet Deposit</div>
                                    <div className="des-inner2">{invoice.total}</div>
                                </div>

                                {invoice.transactions.length > 0 ?
                                    <Table className="table-transaction">
                                        <thead>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <th>Date Time</th>
                                                <th>Payment Method</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <colgroup>
                                                <col style={{ width: "50px" }} />
                                                <col style={{ width: "50px" }} />
                                                <col style={{ width: "50px" }} />
                                                <col style={{ width: "50px" }} />
                                            </colgroup>
                                            {invoice.transactions.map((transaction, index) => (
                                                <tr>
                                                    <td>{transaction.transid}</td>
                                                    <td>{transaction.date}</td>
                                                    <td>{capitalize(invoice.payment_method)}</td>
                                                    <td>{transaction.amountin}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    : ''
                                }

                                <div className="row">
                                    <div className="col invoice-btn">
                                        <div className="form-group mb-0 action-btns">
                                            <button
                                                type="button"
                                                onClick={PrintInvoice}
                                                className={`theme-gradient-btn myImage mr-2 ${hoveredBtnTitle === 'print' ? 'btnHovered' : ''}`}
                                                onMouseEnter={() => setHoveredBtnTitle('print')}
                                                onMouseLeave={() => setHoveredBtnTitle(null)}
                                                style={{ minWidth: 'auto', padding: '8px 12px' }}
                                            >
                                                <FontAwesomeIcon icon={faPrint} />
                                                {hoveredBtnTitle === 'print' ?
                                                    <span className="ml-2">Print</span>
                                                    : ''}
                                            </button>

                                            <button
                                                type="button"
                                                onClick={DownloadPDF}
                                                className={`theme-gradient-btn myImage ${hoveredBtnTitle === 'download' ? 'btnHovered' : ''}`}
                                                onMouseEnter={() => setHoveredBtnTitle('download')}
                                                onMouseLeave={() => setHoveredBtnTitle(null)}
                                                style={{ minWidth: 'auto', padding: '8px 12px' }}
                                            >
                                                <FontAwesomeIcon icon={faDownload} />
                                                {hoveredBtnTitle === 'download' ?
                                                    <span className="ml-2">Download</span>
                                                    : ''}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : "No Record Found"}
                </div>
            }
        </>
    )

}

export default DetailedInvoice;