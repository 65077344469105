import React, { Fragment, useState } from 'react';
import { Loader } from '../Loader';
import { SearchVPNAccount } from './SearchVPNAccount';
import {createRequest, validatePassword} from '../../utils/common';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const UpdateAccountForm = ({toast}) => {

    const [fetchedVPNDetails, setFetchVPNDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [coupon, setCoupon] = useState('');
    const [vpnUserName, setVpnUserName] = useState('');
    const [key, setKey] = useState('email');
    const [state, setState] = useState({
        email : '',
        password : '',
        password_confirmation : '',
    });

    const showResults = (data) => {

        setFetchVPNDetails(data);
        
        if(data.length){

            setCoupon(data[0].coupon);
            setVpnUserName(data[0].vpn_username);
            setShowSearchResult(true);

        }else{

            setCoupon('');
            setVpnUserName('');
            setShowSearchResult(false);
        }

        setLoading(false);
    }

    const vpnUserNameHandler = (e) => {

        if(e.target[e.target.selectedIndex].value !== ""){

            setVpnUserName(e.target[e.target.selectedIndex].value);
            setCoupon(fetchedVPNDetails.filter( val => val.vpn_username === e.target[e.target.selectedIndex].value)[0].coupon)

        }

    }

    const UpdateVpnAccountHandler = async (e) => {
        
        e.preventDefault();

        try {

            setLoading(true);
            setShowSearchResult(false);

            if(key === 'password') validatePassword(state.password)

            const request = key === 'email' ? { username:vpnUserName, email:state.email } : { username:vpnUserName, password:state.password, password_confirmation:state.password_confirmation }

            const data = await createRequest(`/api/v1/resellers/vpn/update/${key}`, request);

            if(data.header.status === 'success'){
                
                
                toast.success(data?.header?.message);

            }else{

                throw new Error(data.header.message);

            }
            
        } catch (error) {

            setShowSearchResult(true);
            toast.error(error.message);
            
        }
        
        setLoading(false);
        

    }

    const formHandler = (e) => {

        setState({
            ...state, 
            [e.target.name]: e.target.value,
        });
    }

    return(
        <Fragment>
            <h2 className="component-heading">Update VPN Account</h2>

            <SearchVPNAccount setData={showResults} toast={toast} setLoading={setLoading} setShowSearchResult={setShowSearchResult}/>

            {loading ? <Loader /> : ''}

            {showSearchResult ?
                <form className="update-vpn-account" onSubmit={UpdateVpnAccountHandler}>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="vpn_username">VPN Username:</label>
                                <select id="vpn_username" onChange={vpnUserNameHandler} className="form-control" defaultValue={""}>
                                    {fetchedVPNDetails.length > 0 ?
                                    fetchedVPNDetails.map((details, i) => {
                                        return <option key={i} value={details.vpn_username}>{details.vpn_username}</option>
                                    }) : 
                                    <option disabled>No Records Found</option>}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="user-coupon">Coupon Code:</label>
                                <input type="text" id="user-coupon" className="form-control" value={coupon} disabled />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="payment-method-btns">
                                <button type="button" onClick={() => setKey('email')} className={key === 'email' ? 'active' : ''}>Email<span className="dot"></span></button>
                                <button type="button" onClick={() => setKey('password')} className={key === 'password' ? 'active' : ''}>Password<span className="dot"></span></button>
                            </div>
                        </div>
                    </div>
                    
                    {
                    key === 'email' ? <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="new_email">New Email:</label>
                                <input onChange={formHandler} type="email" name="email" id="new_email" className="form-control" />
                            </div>
                        </div>
                    </div> : <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="new_password">New Password:</label>
                                <input onChange={formHandler} type={showPassword ? "text" : "password" } name="password" id="new_password" className="form-control" />
                                <FontAwesomeIcon style={{cursor: 'pointer', float: 'right', position: 'relative', marginTop: '-30px', marginRight : '8px'}} icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="confirm_email">Confirm Password:</label>
                                <input onChange={formHandler} type={showPassword ? "text" : "password" } name="password_confirmation" id="confirm_email" className="form-control" />
                            </div>
                        </div>
                    </div>
                    }

                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-0">
                                <button type="submit" className="theme-gradient-btn">Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            : ''}

        </Fragment>
    )
}