import React, { Fragment, useEffect, useState } from 'react';
import { createRequest } from '../../utils/common';
import { CustomDivTable } from '../CustomDivTable';
import { Redirect } from 'react-router-dom';
import { Loader } from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const Invoices = ({toast}) => {

    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [viewInvoiceId, setViewInvoiceId] = useState(null);

    const userslug = useSelector((state) => state?.auth?.user?.custom_url);

    const GetInvoices = async () => {

        setLoading(true);        
        try {
            const data = await createRequest(`/api/v1/resellers/unpaid/invoices`);
            if (data?.header?.status === 'success' ) {
                
                setInvoices(data?.body?.invoices);
            }
            else {
                toast.error(data?.header?.message || "code : rspf-003, Unable to fetch invoices")
            }
        }
        catch(error) {
            toast.error(error?.message)
        }
        setLoading(false);
    }

    const ViewInvoice = async (e) => {
        var pEle = e.target.parentElement.parentElement;
        if (e.target.nodeName !== 'BUTTON') {
            pEle = e.target.parentElement.parentElement.parentElement;
        }
        if (e.target.nodeName === 'path') {
            pEle = e.target.parentElement.parentElement.parentElement.parentElement;
        }
        let invoiceId = pEle.children[0].innerText;
        setViewInvoiceId(Number(invoiceId));
    }

    useEffect(() => {

        GetInvoices();
    }, []);

    return (
        <>
            {viewInvoiceId !== null && viewInvoiceId !== 0 ? <Redirect to={`/${userslug}/billing/invoice/${viewInvoiceId.toString()}`} /> : null}
            <div className="w-box minHeight">
                <h2 className="component-heading mb-0">Unpaid Invoices</h2>

                {loading ? <Loader /> : 
                <Fragment>
                    
                    <CustomDivTable 
                        props={invoices}
                        id="invoices-list-table"
                        classes={['mt-5']}
                        settings={{
                            actionColumn: {
                                enable: true,
                                rules: {
                                    all: {
                                        el: <button onClick={ViewInvoice} className="ct_btn"><FontAwesomeIcon icon={faEye} /></button>
                                    }
                                }
                            },
                            row_identifier: '',
                            date_cols: ['generated_at']
                        }}
                    />
                    

                </Fragment>}
                
            </div>
        </>
    )
}

export default Invoices;