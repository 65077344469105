import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { createRequest } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { CustomDivTable } from "../CustomDivTable";
import { Loader } from "../Loader";
import { CSVLink } from "react-csv";
import { updateProfile } from "../../redux/actions/auth";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import {
  faPaperPlane,
  faTrash,
  faTimes,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";

const AdminCouponList = ({ toast }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToExport, setDataToExport] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [couponId, setCouponId] = useState("");
  const couponEmail = useRef(null);
  const MySwal = withReactContent(Swal);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [filterType, setFilterType] = useState("code"); //code, date, billingType
  const [reload, setReload] = useState(null);
  const [filterData, setFilterData] = useState({
    code: "",
    dateStart: "",
    dateEnd: "",
    billingType: "",
  });
  const csvLinkRef = useRef();
  // const [offset, setOffset] = useState(0);
  const [state, setState] = useState({
    coupon_list: [],
    coupons_data: {},
    coupons_total: 0,
  });
  const greyBorder = {
    border: "1px solid #cecece",
  };

  const elements = {
    code: (
      <input
        type="text"
        className="form-control"
        placeholder="Code"
        onChange={(e) =>
          setFilterData({
            code: e.target.value,
            billingType: null,
            dateStart: null,
            dateEnd: null,
          })
        }
        value={filterData?.code}
      />
    ),
    date: (
      <div className="date-range-picker form-inline">
        <input
          type="date"
          className="form-control-sm mr-2"
          style={greyBorder}
          placeholder="Date"
          value={filterData?.dateStart}
          onChange={(e) =>
            setFilterData({
              ...filterData,
              code: null,
              billingType: null,
              dateStart: e.target.value,
            })
          }
        />
        <input
          type="date"
          className="form-control-sm"
          style={greyBorder}
          placeholder="Date"
          value={filterData?.dateEnd}
          onChange={(e) =>
            setFilterData({
              ...filterData,
              code: null,
              billingType: null,
              dateEnd: e.target.value,
            })
          }
        />
      </div>
    ),
    billingType: (
      <select
        className="form-control-sm"
        style={greyBorder}
        value={filterData?.billingType}
        onChange={(e) =>
          setFilterData({
            billingType: e.target.value,
            code: null,
            dateStart: null,
            dateEnd: null,
          })
        }
      >
        <option value="">All</option>
        <option value="1">Recursive</option>
        <option value="2">Non-Recursive</option>
      </select>
    ),
  };

  // useEffect(() => {

  //     if(csvLinkRef.current){

  //         csvLinkRef.current.link.click()
  //     }

  // }, [csvLinkRef.current]);

  const GetCouponsList = async () => {
    let resellerId = Number(id);
    setLoading(true);
    try {
      if (!isNaN(resellerId)) {
        let filterPayload = { ...filterData,id:resellerId };
        const data = await createRequest(
          `/api/v1/resellers/get/user/listcoupons?page_limit=${pageSize}&page=${currentPage}`,
          filterPayload
        );
        if (data?.header?.status === "success") {
          setState({
            ...state,
            coupon_list: data?.body?.data,
            coupons_data: data?.body,
            coupons_total: data?.body?.total,
          });
        } else {
          throw new Error(data?.header?.message);
        }
      } else {
        throw new Error('User undefined');
      }

    } catch (error) {
      toast.error(
        error?.message || "code : rspf-003, Unable to fetch coupon list"
      );
      setState({
        ...state,
        coupon_list: [],
        coupons_data: [],
        coupons_total: 0,
      });
    } finally {
      setLoading(false);
      setReload(false)
    }


  };

  const DeleteCoupon = async (e) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'delete it!'
    }).then(async(willDelete) => {
      if (willDelete.isConfirmed) {
        try {
          let coupon_id = e.target.localName === 'button' ?
            e.target.parentElement.parentElement.attributes["data-identifier"].value :
            e.target.parentElement.parentElement.parentElement.parentElement.attributes["data-identifier"].value;

          let result = await createRequest("/api/v1/resellers/delete/coupon", {
            couponID: coupon_id,
            id:id
          });
          if (
            result?.header?.code === 70021 &&
            result?.header?.status === "success"
          ) {
            toast.success("Coupon deleted successfully");
            // dispatch(updateProfile()).catch((err) => {
            //   toast.error(err.message);
            // });
            GetCouponsList();
          } else {
            throw new Error(result?.header?.message);
          }
        } catch (error) {
          toast.error(
            error?.message || "code : rspf-003, Unable to delete coupon"
          );
        }
      }
    });
  };

  const downloadCouponsList = async () => {
    setLoading(true);

    try {
      const data = await createRequest(
        `/api/v1/coupons?page_limit=${state.coupons_total}`
      );
      if (data?.header?.status === "success") {
        setDataToExport(data?.body?.data);
        setTimeout(() => csvLinkRef.current.link.click(), 500);
      } else {
        throw new Error(data?.header?.message);
      }
    } catch (error) {
      toast.error(
        error?.message || "code : rspf-013, Unable to download coupons"
      );
    }
    setLoading(false);
  };

  const sendCouponEmail = async (e) => {
    setLoading(true);

    if (!couponEmail.current.value) {
      toast.error("Email address is required");
      setLoading(false);
      return;
    }

    try {
      const data = {
        reseller_id: user.id,
        coupon_id: couponId,
        email: couponEmail.current.value,
      };

      const result = await createRequest(`/api/v1/resellers/coupon/send`, data);
      if (result?.header?.status === "success") {
        toast.success("Coupon sent successfully");
        setShowModal(false);
      } else {
        throw new Error(result?.header?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const sendThisCoupon = (e) => {
    let coupon_id =
      e.target.closest("button").parentElement.parentElement.attributes[
        "data-identifier"
      ].value;
    setCouponId(coupon_id);
    setShowModal(true);
  };

  const SwitchPageSize = (e) => {
    let records_per_page = e.target.value;
    setCurrentPage(1);
    setPageSize(records_per_page);
  };

  const _callback_setCurrentPage = (pageno) => {
    setFilterEnabled(false);
    setCurrentPage(pageno);
  };

  const _callback_setFilterEnabled = async (e) => {
    if (
      filterData.billingType === "" &&
      filterData.code === "" &&
      filterData.dateStart === "" &&
      filterData.dateEnd === ""
    ) {
      setFilterEnabled(false);
    } else {
      setFilterEnabled(false);
      setFilterType("code");
      setFilterData({
        code: "",
        dateStart: "",
        dateEnd: "",
        billingType: "",
      });
      setReload(true);
    }
  };

  useEffect(() => {
    GetCouponsList();
  }, [pageSize, currentPage]);

  return (
    <div className="w-box wp-box">
      <h2 className="component-heading mb-0">Coupons List</h2>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {/* {state?.coupon_list?.length > 0 ? */}
          <div className="row records-switcher mt-4">
            {state?.coupon_list?.length > 0 ? (
              <div className="col-md-4 d-flex flex-column justify-content-end">
                <div className="form-group m-0">
                  <label className="m-0">
                    Show
                    <select
                      id="records_per_page"
                      className="form-control"
                      onChange={SwitchPageSize}
                      value={pageSize}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                    per page
                  </label>
                </div>
              </div>
            ) : (
              <div className="col-md-4"></div>
            )}

            {/* <div className="col-md-8 text-right d-flex justify-content-end filter-mblwrap"> */}
            <div
              className={`col-md-8 text-right d-flex justify-content-end ${filterEnabled && "filter-mblwrap"
                }`}
            >
              <div
                className={`form-group m-0 mr-2 ${filterEnabled && "filter-mbl"
                  }`}
              >
                {filterEnabled ? (
                  <>
                    <button
                      className="btn mw-auto mr-2"
                      style={{
                        padding: "5px 10px",
                        fontSize: "14px",
                        width: "30.99px",
                      }}
                      title="Clear Filter"
                      onClick={_callback_setFilterEnabled}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <select
                      className="form-control-sm mr-2"
                      style={greyBorder}
                      onChange={(e) => setFilterType(e.target.value)}
                      value={filterType}
                    >
                      <option value="code">Coupon Code</option>
                      <option value="date">Date Range</option>
                      <option value="billingType">Billing Type</option>
                    </select>
                  </>
                ) : (
                  <button
                    className="theme-gradient-btn mw-auto"
                    style={{ padding: "5px 10px", fontSize: "14px" }}
                    onClick={() => setFilterEnabled(true)}
                  >
                    Filter
                  </button>
                )}
                <div className="search-field d-inline-block">
                  {filterEnabled ? elements[filterType] : ""}
                </div>
                {filterEnabled ? (
                  <button
                    className="theme-gradient-btn mw-auto ml-2"
                    style={{
                      padding: "5px 10px",
                      fontSize: "14px",
                      width: "30.99px",
                    }}
                    title="Search"
                    onClick={GetCouponsList}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                ) : (
                  ""
                )}
              </div>
              <button
                className="theme-gradient-btn mw-auto"
                style={{ padding: "5px 10px", fontSize: "14px" }}
                onClick={downloadCouponsList}
              >
                Export CSV
              </button>
              <CSVLink
                data={dataToExport}
                filename={`${new Date().toLocaleString()}-coupons.csv`}
                className="hidden"
                ref={csvLinkRef}
                target="_blank"
              />
            </div>
          </div>
          {/* : ''} */}

          {/*  */}

          <Modal show={showModal}>
            <Modal.Header>
              <Modal.Title>Email Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="quantity">Email Address</label>
                <input
                  name="email"
                  type="email"
                  id="email"
                  className="form-control"
                  ref={couponEmail}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="theme-gradient-btn mw-auto"
                onClick={sendCouponEmail}
              >
                Send
              </button>
            </Modal.Footer>
          </Modal>

          <CustomDivTable
            props={state?.coupon_list ? state?.coupon_list : []}
            id="coupon-list-table"
            classes={["mt-3"]}
            settings={{
              actionColumn: {
                enable: true,
                rules: {
                  redeemed: {
                    conditions: {
                      no: (
                        <button onClick={DeleteCoupon} className="ct_btn">
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      ),
                      yes: (
                        <button className="ct_btn" disabled>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      ),
                    },
                  },
                  all: {
                    el: (
                      <button onClick={sendThisCoupon} className="ct_btn">
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    ),
                  },
                },
              },
              row_identifier: "Coupon_ID",
              date_cols: ["generated_at"],
            }}
          />

          {state?.coupon_list?.length ? (
            <div className="pagination-container">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(pageSize)}
                totalItemsCount={state.coupons_data.total}
                pageRangeDisplayed={5}
                prevPageText={<span>‹</span>}
                nextPageText={<span>›</span>}
                firstPageText={<span>«</span>}
                lastPageText={<span>»</span>}
                onChange={_callback_setCurrentPage}
              />
            </div>
          ) : (
            ""
          )}
        </Fragment>
      )}
    </div>
  );
};

export default AdminCouponList;
