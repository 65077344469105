import React, { useState, useEffect, Fragment } from "react";
import { Redirect, withRouter, NavLink, Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PrivateNavLink from "../layout/PrivateNavLink";
import pvpnlogo from "../../assets/img/purevpn-reseller-logo.svg";
import { useDispatch, useSelector } from "react-redux";

const MobileNavbar = ({ props, history }) => {
  const [active, setActive] = useState("");

  const [state, setState] = useState("");

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  useEffect(() => {
    //Set sidebar active on page reload
    if (/dashboard/.test(window.location.href)) {
      setState("dashboard");
      setActive("dashboard");
    } else if (/vpn/.test(window.location.href)) {
      setState("vpn");
      setActive("vpn");
    } else if (/analytics/.test(window.location.href)) {
      setState("analytics");
      setActive("analytics");
    } else if (/developer/.test(window.location.href)) {
      setState("developer");
      setActive("developer");
    } else if (/business-policies/.test(window.location.href)) {
      setState("business-policies");
      setActive("business-policies");
    } else if (/settings/.test(window.location.href)) {
      setState("settings");
      setActive("settings");
    } else if (/reports/.test(window.location.href)) {
      setState("analytics");
      setActive("analytics");
    } else {
    }
  }, []);

  const newNotification = useSelector((state) => state.notification);

  try {
    var user_data = useSelector((state) => state?.auth?.user);
    if (user_data === null || typeof user_data === undefined) {
      throw new Error();
    }
  } catch (error) {
    logout();
  }

  const changeNav = (e) => {
    setActive(e.currentTarget.id);
  };

  // Logout click
  const logout = () => {
    localStorage.clear();
    history.push("login");
  };

  return (
    <Fragment>
      <Navbar color="faded" light>
        {user_data.status == 1 ? null : (
          <div className="activation-bar activation-bar-mbl">
            Your account is deactivated.
            <Link to={""} className="activate-link">
              Activate
            </Link>{" "}
            your account.
          </div>
        )}
        <div className="navbarHead">
          <NavbarBrand href="/" className="mr-auto">
            <div className="logo">
              {/* <img src={require('../../assets/img/logo.png')} alt="" className="img-fluid"/> */}
              <img className="logo-img-nav" src={pvpnlogo} alt="" />
            </div>
          </NavbarBrand>
          <div className="notification-icon noti-mbl">
            <div className="notification-bell bell-mbl">
              {newNotification.state.length == 0 ? null : (
                <span className="notification-count noti-count-mbl">
                  {newNotification.state.length}
                </span>
              )}

              <PrivateNavLink to={"/notifications"}>
                <i className="fa fa-bell"></i>
              </PrivateNavLink>
            </div>
          </div>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        </div>
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                nav
                id="dashboard"
                onClick={changeNav}
                className={active === "dashboard" ? " active" : ""}
              >
                Control Panel <i className="fa fa-angle-down"></i>
              </DropdownToggle>
              <DropdownMenu right onClick={toggleNavbar}>
                <ul className="list-unstyled">
                  <li>
                    <PrivateNavLink
                      to={"/dashboard/overview"}
                      className={""}
                      exact={true}
                      activeClassName="active"
                    >
                      Overview
                    </PrivateNavLink>
                  </li>
                  <li>
                    <PrivateNavLink
                      to={"/coupons/generate"}
                      className={""}
                      exact={true}
                    >
                      Generate Coupon
                    </PrivateNavLink>
                  </li>
                  <li>
                    <PrivateNavLink
                      to={"/vpn/subscriptions"}
                      className={""}
                      exact={true}
                    >
                      Manage VPN Subscriptions
                    </PrivateNavLink>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                nav
                id="analytics"
                onClick={changeNav}
                className={active === "analytics" ? " active" : ""}
              >
                Coupons <i className="fa fa-angle-down"></i>
              </DropdownToggle>
              <DropdownMenu right onClick={toggleNavbar}>
                <ul className="list-unstyled">
                  <li>
                    <PrivateNavLink
                      to={"/coupons/generate"}
                      className={""}
                      exact={true}
                      activeClassName="active"
                    >
                      Generate Coupons
                    </PrivateNavLink>
                  </li>
                  <li>
                    <PrivateNavLink
                      to={"/coupons/list"}
                      className={""}
                      exact={true}
                    >
                      Coupons List
                    </PrivateNavLink>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                nav
                id="developer"
                onClick={changeNav}
                className={active === "developer" ? " active" : ""}
              >
                Manage VPN <i className="fa fa-angle-down"></i>
              </DropdownToggle>
              <DropdownMenu right onClick={toggleNavbar}>
                <ul className="list-unstyled">
                  <li>
                    <PrivateNavLink
                      to={"/vpn/subscriptions"}
                      className={""}
                      exact={true}
                      activeClassName="active"
                    >
                      Manage Subscriptions
                    </PrivateNavLink>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              {/* <DropdownToggle
                nav
                id="vpn"
                onClick={changeNav}
                className={active === "vpn" ? " active" : ""}
              >
                Billing &amp; History <i className="fa fa-angle-down"></i>
              </DropdownToggle> */}
              {/* <DropdownMenu right onClick={toggleNavbar}>
                <ul className="list-unstyled">
                  <li>
                    <PrivateNavLink
                      to={"/billing/pay"}
                      className={""}
                      exact={true}
                      activeClassName="active"
                    >
                      Add Funds
                    </PrivateNavLink>
                  </li>
                  <li>
                    <PrivateNavLink
                      to={"/billing/invoices"}
                      className={""}
                      exact={true}
                    >
                      My Invoices
                    </PrivateNavLink>
                  </li>
                  <li>
                    <PrivateNavLink
                      to={"/billing/transactions"}
                      className={""}
                      exact={true}
                    >
                      Transaction History
                    </PrivateNavLink>
                  </li>
                </ul>
              </DropdownMenu> */}
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                nav
                id="Integration"
                onClick={changeNav}
                className={active === "Integration" ? " active" : ""}
              >
                Integration <i className="fa fa-angle-down"></i>
              </DropdownToggle>
              <DropdownMenu right onClick={toggleNavbar}>
                <ul className="list-unstyled">
                  <li>
                    <PrivateNavLink
                      to={"/integration/form"}
                      className={""}
                      exact={true}
                      activeClassName="active"
                    >
                      Form
                    </PrivateNavLink>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
            <div className="divider"></div>
            <div className="nav-bottom-wrap">
              <NavItem className="dropdown">
                <button>
                  <PrivateNavLink to={"/profile"} className={""} exact={true}>
                    Profile
                  </PrivateNavLink>
                </button>
              </NavItem>
              <NavItem>
                <button onClick={logout}>Logout</button>
              </NavItem>
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </Fragment>
  );
};

export default withRouter(MobileNavbar);
