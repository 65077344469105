import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { doLogout } from "../../redux/actions/auth";
import { NavLink} from "react-router-dom";

const PrivateNavLink = (props) => {

    const dispatch = useDispatch();
    
    try {

        const user_name = useSelector((state) => state?.auth?.user?.custom_url);
        return(
            <NavLink to={`/${user_name}`+props.to} className={props.className} exact={props.exact} activeClassName={props.activeClassName}>{props.children}</NavLink>
        )
        
    } catch (error) {
        return dispatch( doLogout() )
    }

    
};
export default PrivateNavLink