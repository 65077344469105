import React, { Fragment, useEffect, useState } from 'react';
import FAQ from '../FAQ';
import { CreateAccountForm } from './CreateAccountForm';
import { EnableDisable } from './EnableDisable';
import { UpdateAccountForm } from './UpdateAccountForm';

const CreateIcon = ({props}) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.78 115.78">
            <defs>
                <linearGradient id="normal" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{stopColor:"rgb(1,122,172)",stopOpacity:"1"}} />
                    <stop offset="100%" style={{stopColor:"rgb(23,215,177)",stopOpacity:"1"}} />
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2" fill="url(#normal)">
                <g id="Isolation_Mode" data-name="Isolation Mode">
                    <rect className="cls-1" x="45.65" y="53.62" width="28.43" height="4.6" transform="translate(-22 58.89) rotate(-45.13)"/>
                    <path className="cls-1" d="M69.06,115.78l-21-48.07L0,46.72,115.78,0ZM11.86,46.89l39.7,17.33,17.33,39.69,38.58-95.6Z"/>
                    <rect className="cls-1" x="28.63" y="82.78" width="4.14" height="4.6" transform="translate(-51.16 46.61) rotate(-44.99)"/>
                    <rect className="cls-1" x="34.48" y="76.93" width="4.14" height="4.59" transform="translate(-45.32 49.05) rotate(-45)"/>
                    <rect className="cls-1" x="40.33" y="71.09" width="4.14" height="4.6" transform="translate(-39.47 51.46) rotate(-44.99)"/>
                </g>
            </g>
        </svg>
    )
}

const UpdateIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.33 133.1">
            <defs>
                <linearGradient id="normal" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{stopColor:"rgb(1,122,172)",stopOpacity:"1"}} />
                    <stop offset="100%" style={{stopColor:"rgb(23,215,177)",stopOpacity:"1"}} />
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2" fill="url(#normal)">
                <g id="Isolation_Mode" data-name="Isolation Mode">
                    <path className="cls-1" d="M17.47,108.75A59.69,59.69,0,0,1,52.53,7.3l.54,4.56A55.09,55.09,0,0,0,20.72,105.5Z"/>
                    <path className="cls-1" d="M59.67,18.36a9.18,9.18,0,1,1,9.18-9.18A9.19,9.19,0,0,1,59.67,18.36Zm0-13.77a4.59,4.59,0,1,0,4.59,4.59A4.6,4.6,0,0,0,59.67,4.59Z"/>
                    <path className="cls-1" d="M66.8,125.8l-.54-4.56A55.1,55.1,0,0,0,98.62,27.6l3.25-3.24A59.69,59.69,0,0,1,66.8,125.8Z"/>
                    <path className="cls-1" d="M59.67,133.1a9.18,9.18,0,1,1,9.18-9.18A9.19,9.19,0,0,1,59.67,133.1Zm0-13.77a4.59,4.59,0,1,0,4.59,4.59A4.6,4.6,0,0,0,59.67,119.33Z"/>
                    <path className="cls-1" d="M59.67,110.15a43.6,43.6,0,1,1,43.6-43.6H98.68a39,39,0,1,0-39,39Z"/>
                    <path className="cls-1" d="M103.2,69l-4.58-.25c0-.72.06-1.45.06-2.17h4.59C103.27,67.35,103.25,68.16,103.2,69Z"/>
                    <path className="cls-1" d="M67,109.54,66.21,105a38.79,38.79,0,0,0,4.27-1l1.27,4.41A43.45,43.45,0,0,1,67,109.54Zm9.4-2.7-1.76-4.24a40.61,40.61,0,0,0,3.93-1.9l2.23,4A44.49,44.49,0,0,1,76.37,106.84Zm8.53-4.73-2.65-3.74a40.49,40.49,0,0,0,3.42-2.73l3,3.42A41.5,41.5,0,0,1,84.9,102.11Zm7.28-6.51-3.42-3.06a37.53,37.53,0,0,0,2.73-3.42l3.74,2.66A41.5,41.5,0,0,1,92.18,95.6Zm5.66-8-4-2.22a36.73,36.73,0,0,0,1.9-4L100,83.23A42.51,42.51,0,0,1,97.84,87.64Zm3.74-9-4.41-1.27a38.28,38.28,0,0,0,1-4.27l4.52.76A42.94,42.94,0,0,1,101.58,78.61Z"/>
                    <path className="cls-1" d="M59.67,110.15v-4.59c.71,0,1.44,0,2.17,0l.25,4.58C61.28,110.13,60.46,110.15,59.67,110.15Z"/>
                    <path className="cls-1" d="M59.67,89.34,39.93,78V55.15L59.67,43.76,79.41,55.15V78ZM43,76.17l16.66,9.61,16.65-9.61V56.94L59.67,47.32,43,56.94Z"/>
                    <polygon className="cls-1" points="59.67 68.33 40.7 57.38 42.24 54.71 59.67 64.77 77.09 54.71 78.63 57.38 59.67 68.33"/>
                    <rect className="cls-1" x="58.12" y="66.55" width="3.09" height="21.01"/>
                    <polygon className="cls-1" points="96.86 22.95 98.62 34.56 109.28 25.73 96.86 22.95"/>
                    <polygon className="cls-1" points="26.24 113.5 23.21 102.16 13.59 112.11 26.24 113.5"/>
                </g>
            </g>
        </svg>
    )
}

const EnableIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.38 119.4">
            <defs>
                <linearGradient id="normal" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{stopColor:"rgb(1,122,172)",stopOpacity:"1"}} />
                    <stop offset="100%" style={{stopColor:"rgb(23,215,177)",stopOpacity:"1"}} />
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2" fill="url(#normal)">
                <g id="Isolation_Mode" data-name="Isolation Mode">
                    <path className="cls-1" d="M65.93,119.4H53.48L50.9,106.58a46.06,46.06,0,0,1-7-1.89l-8.61,9.81-10.78-6.22L28.65,95.9a47.53,47.53,0,0,1-5.17-5.16L11.11,94.91,4.91,84.16l9.78-8.64a47,47,0,0,1-1.89-7L0,65.94,0,53.46l12.8-2.58a47.15,47.15,0,0,1,1.88-7L4.91,35.22l6.2-10.73,12.37,4.16a48.53,48.53,0,0,1,5.17-5.17L24.47,11.09l10.78-6.2,8.62,9.79a48.35,48.35,0,0,1,7-1.89L53.48,0H65.93L68.5,12.8a47,47,0,0,1,7,1.89l8.61-9.8,10.79,6.2L90.74,23.47a47.71,47.71,0,0,1,5.18,5.17l12.37-4.15,6.2,10.73-9.76,8.63a48.87,48.87,0,0,1,1.9,7l12.75,2.57V65.94l-12.75,2.55a48.65,48.65,0,0,1-1.9,7.05l9.76,8.62-6.2,10.75L95.92,90.74a47.71,47.71,0,0,1-5.18,5.17l4.18,12.37L84.16,114.5l-8.63-9.81a47.11,47.11,0,0,1-7,1.89Zm-8.7-4.59h4.94l2.46-12.26,1.59-.24a43.12,43.12,0,0,0,9.16-2.46l1.49-.59,8.25,9.38,4.25-2.45-4-11.83,1.26-1a43.77,43.77,0,0,0,6.74-6.73l1-1.26,11.81,4,2.45-4.25L99.3,76.88l.59-1.5a42.73,42.73,0,0,0,2.47-9.18l.24-1.59,12.19-2.43v-5L102.6,54.77l-.24-1.59A42.49,42.49,0,0,0,99.89,44l-.58-1.49,9.33-8.25L106.19,30,94.37,34l-1-1.25A42.48,42.48,0,0,0,86.63,26l-1.26-1,4-11.83L85.1,10.74l-8.23,9.37-1.5-.58a42.6,42.6,0,0,0-9.15-2.46l-1.59-.24L62.17,4.59H57.23L54.77,16.82l-1.59.24A43.12,43.12,0,0,0,44,19.52l-1.5.59-8.24-9.37L30,13.19,34,25l-1.25,1A43.2,43.2,0,0,0,26,32.76L25,34,13.21,30l-2.45,4.24,9.35,8.27L19.52,44a42.34,42.34,0,0,0-2.45,9.14l-.25,1.59L4.6,57.22v5l12.21,2.45.25,1.59a42.36,42.36,0,0,0,2.46,9.14l.58,1.5-9.35,8.25,2.45,4.25,11.82-4,1,1.25a43.2,43.2,0,0,0,6.72,6.73l1.26,1-4,11.84,4.26,2.45,8.23-9.37,1.5.58a42,42,0,0,0,9.17,2.46l1.59.24Z"/>
                    <polygon className="cls-1" points="48.08 71.62 36.16 59.7 48.08 47.78 51.66 51.36 43.32 59.7 51.66 68.04 48.08 71.62"/>
                    <polygon className="cls-1" points="71.3 71.62 67.72 68.04 76.06 59.7 67.72 51.36 71.3 47.78 83.22 59.7 71.3 71.62"/>
                    <rect className="cls-1" x="42.21" y="57.17" width="34.97" height="5.06" transform="translate(-9.48 108.1) rotate(-79.99)"/>
                    <rect className="cls-1" x="57.39" y="20.67" width="4.59" height="4.59"/>
                    <rect className="cls-1" x="57.39" y="94.13" width="4.59" height="4.59"/>
                    <rect className="cls-1" x="20.66" y="57.4" width="4.59" height="4.59"/>
                    <rect className="cls-1" x="94.12" y="57.4" width="4.59" height="4.59"/>
                </g>
            </g>
        </svg>
    )
}

const DeleteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.81 119.11">
            <defs>
                <linearGradient id="normal" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{stopColor:"rgb(1,122,172)",stopOpacity:"1"}} />
                    <stop offset="100%" style={{stopColor:"rgb(23,215,177)",stopOpacity:"1"}} />
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2" fill="url(#normal)">
                <g id="Isolation_Mode" data-name="Isolation Mode">
                    <path className="cls-1" d="M107.92,107.63H93.68V103h14.24a2.3,2.3,0,0,0,2.29-2.3V54.82a2.3,2.3,0,0,0-2.29-2.3H6.89a2.3,2.3,0,0,0-2.3,2.3v45.92a2.3,2.3,0,0,0,2.3,2.3H21.13v4.59H6.89A6.9,6.9,0,0,1,0,100.74V54.82a6.9,6.9,0,0,1,6.89-6.89h101a6.9,6.9,0,0,1,6.89,6.89v45.92A6.9,6.9,0,0,1,107.92,107.63Z"/>
                    <rect className="cls-1" x="2.3" y="75.48" width="110.21" height="4.59"/>
                    <polygon className="cls-1" points="89.55 50.22 84.96 50.22 84.96 16.53 73.02 4.59 29.85 4.59 29.85 50.22 25.26 50.22 25.26 0 74.92 0 89.55 14.63 89.55 50.22"/>
                    <polygon className="cls-1" points="87.25 17.87 71.68 17.87 71.68 2.3 76.27 2.3 76.27 13.28 87.25 13.28 87.25 17.87"/>
                    <rect className="cls-1" x="35.84" y="21.94" width="43.12" height="4.59"/>
                    <rect className="cls-1" x="35.84" y="30.6" width="43.12" height="4.59"/>
                    <rect className="cls-1" x="35.84" y="39.27" width="43.12" height="4.59"/>
                    <rect className="cls-1" x="35.84" y="13.28" width="21.56" height="4.59"/>
                    <rect className="cls-1" x="11.48" y="89.26" width="91.85" height="4.59"/>
                    <rect className="cls-1" x="25.26" y="91.55" width="4.59" height="27.55"/>
                    <rect className="cls-1" x="33.79" y="91.55" width="4.59" height="27.55"/>
                    <rect className="cls-1" x="42.31" y="91.55" width="4.59" height="27.55"/>
                    <rect className="cls-1" x="50.84" y="91.55" width="4.59" height="27.55"/>
                    <rect className="cls-1" x="59.37" y="91.55" width="4.59" height="27.55"/>
                    <rect className="cls-1" x="67.9" y="91.55" width="4.59" height="27.55"/>
                    <rect className="cls-1" x="76.43" y="91.55" width="4.59" height="27.55"/>
                    <rect className="cls-1" x="84.96" y="91.55" width="4.59" height="27.55"/>
                    <rect className="cls-1" x="17.91" y="60.79" width="6.43" height="6.43"/>
                    <rect className="cls-1" x="30.77" y="60.79" width="6.43" height="6.43"/>
                </g>
            </g>
        </svg>
    )
}

const faqs = [
    {
        q: "What is difference between creating a coupon and creating a VPN account?",
        a: "Creating a coupon helps manage VPN subscriptions easily without any hassle, create multiple coupons and sell as per your convenience and easily get refunded to your wallet if code isn’t redeemed within a year. Coupon once redeem activates the VPN account and its billing cycle, whereas creating a VPN account directly activates the VPN account right away."
    },
    {
        q: "What happens when I update VPN account Email and Password?",
        a: "VPN account will be shifted to another email and new credentials will be sent to the new user email id."
    },
    {
        q: "Will updating email id or password effect VPN expiry?",
        a: "No, VPN expiry will not be effected and will be availble to the new user with the remaining days."
    },
    {
        q: "Will my VPN expiry change if I disable the account and later enable it again?",
        a: "No, VPN expiry will remain same. Disabling and enabling helps reseller control VPN account usage against end-users"
    },
    {
        q: "What happens when I delete a VPN account?",
        a: "VPN account once deleted will be marked suspended and cannot be re-used by any other user, and is non-refundable."
    }
];

const Subscriptions = ({toast}) => {
    const [currentTab, switchTab] = useState('create');

    const DirectNavigate = () => {
        let route = window.location.pathname;
        let tabTile = route.split('/').pop();
        if (tabTile !== 'subscriptions') {
            switchTab(tabTile);
        }
    }

    useEffect(() => {
        DirectNavigate();
    }, []);

    useEffect(() => {

        if(window.scrollY > 300) window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        
    }, [currentTab]);

    return(
        <Fragment>
            <h2 className="component-heading">Manage VPN Subscriptions</h2>
            <p className="heading-slogan">Manage VPN subscriptions, Create, Update, Enable/Disable, Delete VPN account.</p>

            <div className="w-box">
                <div className="tab-triggers">
                    <div className={`trigger-action ${currentTab==='create'?'selected':''}`} onClick={() => switchTab('create')}>
                        <div className="t-icon">
                            <CreateIcon props={currentTab} />
                        </div>
                        <div className="t-title">Create</div>
                    </div>
                    <div className={`trigger-action ${currentTab==='update'?'selected':''}`} onClick={() => switchTab('update')}>
                        <div className="t-icon">
                            <UpdateIcon />
                        </div>
                        <div className="t-title">Update</div>
                    </div>
                    <div className={`trigger-action ${currentTab==='enable'?'selected':''}`} onClick={() => switchTab('enable')}>
                        <div className="t-icon">
                            <EnableIcon />
                        </div>
                        <div className="t-title">Enable/Disable</div>
                    </div>
                    <div className={`trigger-action ${currentTab==='delete'?'selected':''}`} onClick={() => switchTab('delete')}>
                        <div className="t-icon">
                            <DeleteIcon />
                        </div>
                        <div className="t-title">Delete</div>
                    </div>
                </div>

                <div className="tab-areas">
                    <div className={`pt-5 pb-5 ${currentTab==='create'?'':'d-none'}`}>
                        <CreateAccountForm toast={toast} />
                    </div>
                    <div className={`pt-5 pb-5 ${currentTab==='update'?'':'d-none'}`}>
                        <UpdateAccountForm toast={toast}/>
                    </div>
                    <div className={`pt-5 pb-5 ${currentTab==='enable'?'':'d-none'}`}>
                        <EnableDisable toast={toast}/>
                    </div>
                    <div className={`pt-5 pb-5 ${currentTab==='delete'?'':'d-none'}`}>
                        <EnableDisable toast={toast} props={{component:'delete'}} />
                    </div>                    
                </div>
            </div>

            <FAQ props={faqs} />
        </Fragment>
    )
}

export default Subscriptions;