import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import FAQ from "../FAQ";
import { createRequest, productNameMap } from "../../utils/common";
import Loader from "../layout/Loader";
import { Tooltip } from "../Tooltip";
import PrivateNavLink from "../layout/PrivateNavLink";
import { updateProfile } from "../../redux/actions/auth";
import { Modal, Table, Tabs, Tab } from "react-bootstrap";

const GenerateCoupons = ({ history, toast }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const [loading, setLoading] = useState(true);
  const [hasLowBalance, setHasLowBalance] = useState(false);
  const [showPrices, setShowPrices] = useState(false);
  const [state, setState] = useState({
    products: null,
    addons: null,
    product_type: "onetime",
    addon_names: [],
    addon_type: null,
    products_data: {},
    billing_cycle: "",
    product_id: 0,
    addon_id: null,
    quantity: 1,
    prefix: "",
  });

  useEffect(() => {
    getCouponProducts();
  }, []);

  useEffect(() => {
    setState({
      ...state,
      billing_cycle: document.getElementById("product").value,
    });
  }, [state.product_type]);

  const getCouponProducts = async () => {
    try {
      const data = await createRequest("/api/v1/resellers/products");
      if (data?.header?.code === 50001 && data?.header?.status === "success") {
        setState({
          ...state,
          products_data: data?.body,
          products: data?.body[state.product_type]?.product,
          addons: data?.body[state.product_type]?.addon,
          billing_cycle: Object.keys(
            data?.body[state.product_type]?.product
          ).filter((val, index) => index === 0)[0],
          product_id:
            data?.body[state.product_type]?.product[
              Object.keys(data?.body[state.product_type]?.product).filter(
                (val, index) => index === 0
              )[0]
            ]?.id,
          addon_names: data?.body?.addon_type,
        });
      } else {
        toast.error(
          data?.header?.message || "code : rspf-003, Unable to fetch products"
        );
      }
    } catch (error) {
      toast.error(error?.message);
    }

    setLoading(false);
  };

  const productTypeChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      products: state?.products_data[e.target.value]?.product,
      addons: state?.products_data[e.target.value]?.addon,
      product_id:
        state?.products_data[e.target.value]?.product[state.billing_cycle] ===
        undefined
          ? state?.products_data[e.target.value]?.product[
              Object.keys(state?.products_data[e.target.value].product)[0]
            ].id
          : state?.products_data[e.target.value]?.product[state.billing_cycle]
              .id,
    });
  };

  const couponFormChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setState({
      ...state,
      quantity: 1,
      prefix: "",
    });
  };

  const validateBalance = () => {
    let total_price =
      +state.products[state.billing_cycle].price * +state.quantity;

    if (total_price > user.balance) {
      setHasLowBalance(true);
      throw new Error(
        `Insufficient Balance! your current balance is: $${user.balance} and transaction amount is: $${total_price}`
      );
    }
  };

  const couponFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      validateBalance();

      const data = {
        quantity: state.quantity,
        billing_cycle: state.billing_cycle,
        product_id: state.product_id,
        //addon_id : state.addon_id,
        addon_type: state.addon_type,
        billing_type: state.product_type === "onetime" ? 2 : 1,
        prefix: state.prefix,
      };

      const request = await createRequest("/api/v1/coupons/create", data);

      if (
        request?.header?.code === 70001 &&
        request?.header?.status === "success"
      ) {
        toast.success(request?.header?.message);
        resetForm();

        dispatch(updateProfile()).catch((err) => {
          toast.error(err.message);
        });

        history.push(`../coupons/list`);
      } else {
        throw new Error(request?.header?.message);
      }
    } catch (error) {
      toast.error(
        error?.message || "code : rspf-301, Unable to generate coupons"
      );
    }

    setLoading(false);
  };

  const productChange = (e) => {
    setState({
      ...state,
      billing_cycle: e.target.value,
      product_id:
        e.target[e.target.selectedIndex].getAttribute("data-product_id"),
    });
  };

  const addonChange = (e) => {
    setState({
      ...state,
      addon_id:
        e.target[e.target.selectedIndex].value == 0
          ? null
          : e.target[e.target.selectedIndex].value,
    });
  };

  const addonNameChange = (e) => {
    setState({
      ...state,
      addon_type:
        e.target[e.target.selectedIndex].value == 0
          ? null
          : e.target[e.target.selectedIndex].value,
    });
  };

  const faqs = [
    {
      q: "How does the billing works when a coupon is generated & when i will be charged?",
      a: "Coupon $ Value will automatically be adjusted from the wallet once generated.",
    },
    {
      q: "Does coupon code has an expiry?",
      a: "Yes, Once generated coupon can be utilized within an year.",
    },
    {
      q: "What if the code is not utilized /redeemed within a year?",
      a: "Code once generated will expire in a year, however reseller can delete the code if not redeemed within a year and $ value will be refunded to the wallet.",
    },
    {
      q: "What if I want to delete the code after it has been redeemed?",
      a: "Code once redeemed cannot be deleted or refunded. However reseller can update the VPN subscriptions Credientials associated with it from Manage VPN subscription.",
    },
  ];
  return (
    <Fragment>
      <Loader loading={loading} />
      <h2 className="component-heading">Generate Coupons</h2>
      <p className="heading-slogan">
        Generate coupons in bulk or single as per your requirements based on the
        VPN subscription you want.
      </p>

      <div className="w-box">
        <form onSubmit={couponFormSubmit} className="generate-coupons-form">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="product">
                  Select Product:
                  <Tooltip>
                    <>
                      <div>Select VPN Subscription Billing</div>
                      <div>Cycle / Tenure for your Users</div>
                      <p
                        style={{ color: "#007bff", cursor: "pointer" }}
                        onClick={() => setShowPrices(true)}
                      >
                        View pricing for products
                      </p>
                    </>
                  </Tooltip>
                </label>
                <select
                  onChange={productChange}
                  value={state?.billing_cycle}
                  id="product"
                  className="form-control"
                >
                  {state?.products ? (
                    Object.keys(state?.products).map((val, index) => (
                      <option
                        key={index}
                        value={val}
                        data-product_id={state?.products[val].id}
                        data-billing_cycle={val}
                      >
                        {" "}
                        {productNameMap[val]}{" "}
                      </option>
                    ))
                  ) : (
                    <option value="0" disabled>
                      No Products found
                    </option>
                  )}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Product Type:</label>
                {/* <div className="form-check">
                  <input
                    type="radio"
                    name="product_type"
                    id="recursive"
                    value="recurring"
                    checked={state.product_type === "recurring"}
                    onChange={productTypeChange}
                  />
                  <label htmlFor="recursive">Recursive</label>
                  <Tooltip>
                    <div>Coupons once redeemed will be auto</div>
                    <div>renewed after billing cycle expires,</div>
                    <div>also adjusting $ value from the wallet</div>
                    <div>on renewal</div>
                  </Tooltip>
                </div> */}
                <div className="form-check">
                  {/* <input
                    type="radio"
                    name="product_type"
                    id="non-recursive"
                    value="onetime"
                    checked={state.product_type === "onetime"}
                    onChange={productTypeChange}
                  /> */}
                  <label htmlFor="non-recursive">Non-Recursive</label>
                  <Tooltip>One-Time redeemable coupon</Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="addon">
                  Select Addon:
                  <Tooltip>
                    <>
                      <div>Additional Add-on with VPN:</div>
                      <div>
                        Dedicated IP @
                        {state?.addons
                          ? state?.addons["dedicated_ip_monthly_price"] ===
                            undefined
                            ? "1.00"
                            : state?.addons["dedicated_ip_monthly_price"]
                          : "No addon found"}
                        $ per month
                      </div>
                      <div>
                        Port Forwarding @
                        {state?.addons
                          ? state?.addons["port_forwarding_monthly_price"] ===
                            undefined
                            ? "0.25"
                            : state?.addons["port_forwarding_monthly_price"]
                          : "No addon found"}
                        $ per month
                      </div>
                    </>
                  </Tooltip>
                </label>
                <select
                  id="addon"
                  onChange={addonNameChange}
                  className="form-control"
                >
                  <option value="0"> None </option>
                  {state?.addon_names.length
                    ? state?.addon_names.map((val, index) => (
                        <option key={index} value={val.id}>
                          {" "}
                          {val.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="binding_prefix">
                  Code Prefix for Binding:
                  <Tooltip>
                    <>
                      <div>Set your person prefix code</div>
                      <div>brand for example: purevpn01. This</div>
                      <div>helps brand stand out from others.</div>
                    </>
                  </Tooltip>
                </label>
                <input
                  type="text"
                  name="prefix"
                  value={state.prefix}
                  onChange={couponFormChange}
                  id="binding_prefix"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="quantity">Quantity</label>
                <input
                  onChange={couponFormChange}
                  name="quantity"
                  type="number"
                  id="quantity"
                  value={state.quantity}
                  className="form-control"
                  min="1"
                  max="60"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group mb-0">
                {user.status == 1 ? (
                  <button type="submit" className="theme-gradient-btn">
                    Generate Coupons
                  </button>
                ) : null}
                {hasLowBalance ? (
                  <PrivateNavLink
                    to={"/billing/pay"}
                    className={""}
                    exact={true}
                  >
                    <span
                      style={{
                        color: "#0dac7f",
                        marginLeft: "8px",
                        display: "inline-block",
                        textDecoration: "underline",
                      }}
                    >
                      Add Fund
                    </span>
                  </PrivateNavLink>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </form>
      </div>

      <Modal show={showPrices} onHide={() => setShowPrices(false)}>
        <Modal.Header closeButton closeLabel={"Close"}>
          <Modal.Title>Product Pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(state.products_data).length ? (
            <Tabs
              defaultActiveKey={"recurring"}
              transition={true}
              id="noanim-tab-example"
              className="mb-3"
            >
              <Tab eventKey="recurring" title="Recursive" tabClassName="px-2">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Billing Cycle</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  {Object.keys(state.products_data["recurring"].product).map(
                    (val, index) => (
                      <>
                        <tbody>
                          <tr key={index}>
                            <td>{productNameMap[val]}</td>
                            <td>
                              {
                                state.products_data["recurring"].product[val]
                                  .price
                              }
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )
                  )}
                </Table>
              </Tab>
              <Tab eventKey="onetime" title="Non-Recursive" tabClassName="px-2">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Billing Cycle</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  {Object.keys(state.products_data["onetime"].product).map(
                    (val, index) => (
                      <>
                        <tbody>
                          <tr key={index}>
                            <td>{productNameMap[val]}</td>
                            <td>
                              {
                                state.products_data["onetime"].product[val]
                                  .price
                              }
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )
                  )}
                </Table>
              </Tab>
            </Tabs>
          ) : (
            "No product(s) found"
          )}
        </Modal.Body>
      </Modal>

      <FAQ props={faqs} />
    </Fragment>
  );
};

export default GenerateCoupons;
