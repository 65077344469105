import React, { Fragment, useEffect, useState } from "react";
import { createRequest } from "../../utils/common";
import { Loader } from "../Loader";
import { MDBDataTable } from "mdbreact";
import "../../../node_modules/mdbreact/dist/css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import TransactionDetails from "./TransactionDetails";

const Transactions = ({ toast }) => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState([]);

  const columns = [
    {
      label: "ID",
      field: "id",
    },
    {
      label: "Date",
      field: "date",
    },
    {
      label: "Transaction ID",
      field: "transaction_id",
    },
    {
      label: "Payment Method",
      field: "paymentmethod",
    },
    {
      label: "Description",
      field: "description",
    },
    {
      label: "AmountIN",
      field: "amountin",
    },
    {
      label: "AmountOUT",
      field: "amountout",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ];

  const viewTransaction = async (id) => {
    setModal(true);

    try {
      const result = await createRequest(`/api/v1/resellers/transaction`, {
        id,
      });

      if (result?.header?.status === "success") {
        setTransaction(result?.body?.transaction);
      } else {
        throw new Error(
          result?.header?.message ||
            "code : rspf-003, Unable to fetch transaction"
        );
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    const GetTransactions = async () => {
      setLoading(true);
      try {
        const data = await createRequest(`/api/v1/resellers/transactions`);
        if (data?.header?.status === "success") {
          setTransactions({
            columns: columns,
            rows: data?.body?.transactions.map((e) => {
              //return {...e, clickEvent: () => viewTransaction(e.id), actions: "view"}
              return {
                ...e,
                actions: (
                  <button className="ct_btn" onClick={() => viewTransaction(e.id)} >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faEye}
                    />
                  </button>
                ),
              };
            }),
          });
        } else {
          toast.error(
            data?.header?.message ||
              "code : rspf-003, Unable to fetch transactions"
          );
        }
      } catch (error) {
        toast.error(error?.message);
      }
      setLoading(false);
    };

    GetTransactions();
  }, []);

  const closemodal = () => {
    setTransaction("");
    setModal(false);
  };

  const columnss = transactions[0] && Object.keys(transactions[0]);

  return (
    <div className="w-box wp-box">
      <h2 className="component-heading mb-0">Transactions</h2>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MDBDataTable
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={transactions}
            searchBottom={false}
            sortable={false}
            noBottomColumns={true}
            className="mt-3"
            entriesLabel="Rows per page"
            hover
            pagingTop
            searchTop
            fullPagination
            barReverse
          />

          <Modal show={modal}>
            <Modal.Header>
              <Modal.Title>
                <h3 className="modal-title">Transaction Detail</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {transaction.length ? (
                transaction.map((val) => <TransactionDetails val={val} />)
              ) : (
                <Loader />
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary theme-gradient-btn mw-auto"
                data-dismiss="modal"
                onClick={() => closemodal()}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      )}
    </div>
  );
};

export default Transactions;
