import React,{useState} from 'react';
import { useHistory } from "react-router-dom";

import logo from '../../assets/img/purevpn-logo-black.png'
import { UpdatePassword } from '../../services/UpdatePassword'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const UpdatePasswordForm = ({client_id, MySwal, loading, toast}) => {

    const [state, setState] = useState({
        password: '',
        password_confirmation: '',
        error: '',
        passwordError: false,
        confirmPasswordError: false,
        client_id: client_id,
    });

    const history = useHistory();
    
    const { executeRecaptcha } = useGoogleReCaptcha();

    const submit = async (e) => {
        
        e.preventDefault();

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            return
        }

        if(!validateForm()) return false;

        setState({ 
            ...state,
            passwordError: false,
            nameError: false,
            confirmPasswordError: false,
        });

        loading(true);

        const token = await executeRecaptcha('update_password');

        UpdatePassword(state, token).then(res => {

            if(res.header.status === "success"){
                MySwal.fire({
                    title: "<h3>" + res.header.message + "<h3>",
                    icon:'success',
                    text:'Password updated successfully'
                });
                setState({...state, password:'', password_confirmation:''});
                loading(false);
                
                history.push('/login')

            }else{
                setState({...state, error: res.header.message, password:'', password_confirmation:''});
                MySwal.fire({
                    title: res.header.message,
                    icon:'error',
                    //onClose: () => checkAuth(res, 'register')
                });
                loading(false);
            }
        }).catch((err) => {
            setState({...state, error: err, visible: true, password:''});
            toast.error(err.message);
            loading(false);
        });
    };

    const validateForm = () => {

        const { password, password_confirmation } = state;

        if(password === ''){
            setState({ ...state, passwordError:true });
            return false;
        }

        if(password !== password_confirmation){
            setState({ ...state, confirmPasswordError:true });
            return false;
        }

        return true;
    }

    const handleChange = (e) => {
        setState({
            ...state, [e.target.name]: e.target.value
        });
    };

    // Password validation on blur
    const passValidate = (e) => {
        e.target.value === '' ? setState({...state, passwordError:true}) : setState({...state, passwordError:false});
    };


    return(
        <div className="accountBox">
            <div className="accountHead">
                <img src={logo} className="img-responsive" alt="" />
                <h3>Update Password</h3>
            </div>
            <form className="reset-form" onSubmit={submit} data-id="1" noValidate>
                <div className="accountBody">
                    <div className="formBox" id="passwordBox">
                        <div className="form-group">
                            <input type="password"
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Password"
                                    name="password"
                                    onBlur={passValidate}
                                    value={state.password}
                            />
                            <span className={"errorText " + (state.passwordError ? 'show' : '')}>Password is required</span>
                        </div>
                    </div>
                    <div className="formBox" id="confirmPasswordBox">
                        <div className="form-group">
                            <input type="password"
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    name="password_confirmation"
                                    value={state.password_confirmation}
                            />
                            <span className={"errorText " + (state.confirmPasswordError ? 'show' : '')}>Passwords don't match</span>
                        </div>
                    </div>
                </div>
                <div className="accountFooter">
                    <div className="fullwidthbutton text-center">
                        <button type="submit" id="accountRegiter">Update Password</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
