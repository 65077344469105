import {LOGIN_FAILURE, LOGIN_SUCCESS, UPDATE_PROFILE} from "../actions/types.js";
import {getLocalStorage,validateJSON} from "../../utils/common";

const INITIAL_STATE = {

    user: validateJSON(getLocalStorage('user')),
    //user_data: getLocalStorage('user_data')

};

export const authReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload.user
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                user: action.payload.user,
                user_data: action.payload.user_data,
                balance: action.payload.balance,
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                user: action.payload.user
            }
        default:
            return {...state};
    }
};