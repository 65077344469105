import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const GenerateForm = (props) => {
  const [copied, setCopied] = useState("Copy");
  const [value, setValue] = useState(`
  <iframe
    title="Redeem"
    src="${window.location.protocol}//${window.location.hostname}/redeem"
    height="100%" width="100%"
    style="border:none">
  </iframe>`);

  return (
    <div className="textarea-redeemwrap">
      <textarea
        className="textarea-redeem"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        disabled
      />

      <CopyToClipboard
        options={{ debug: props.debug, message: "" }}
        text={value}
        onCopy={() => setCopied("Copied")}
      >
        <button className="copy theme-gradient-btn mw-auto">
          {" "}
          <FontAwesomeIcon icon={faCopy} size="2x" />
          <span className="copy-span">{copied}</span>
        </button>
      </CopyToClipboard>
    </div>
  );
};

export default GenerateForm;
