import React from 'react';

const InnerLoader = ({loading}) => {
    return(
        <div className={'statsBoxLoader ' + (loading ? '' : 'hide') }>
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    )
};
export default InnerLoader