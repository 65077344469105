import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "919c527a-4081-4eee-a3b7-aa3f0a5c1370",
  wsHost: process.env.REACT_APP_NOTIFICATION_HOST,
  wsPort: 6001,
  forceTLS: false,
  disableStats: true,
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      >
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </GoogleReCaptchaProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
