import {LOGIN_FAILURE, LOGIN_SUCCESS, UPDATE_BALANCE, UPDATE_PROFILE} from "./types.js";
import {setLocalStorage, removeLocalStorage, createRequest} from "../../utils/common";

export const loginSuccess = (data) => async (dispatch) => {

    try{
        
        setLocalStorage('user_data', data?.body?.access_token);
        delete data?.body['access_token'];
        setLocalStorage('user', JSON.stringify(data?.body));
        
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                user: data?.body
            }
        });

    }catch (error) {
        throw new Error('code : rspf-login-1011, Unable to Login')
    }
}

export const doLogout = () => async (dispatch) => {

    try{

        removeLocalStorage('user');
        removeLocalStorage('user_data');
        removeLocalStorage('balance');

        dispatch({
            type: LOGIN_FAILURE,
            payload: {
                user: null,
                user_data: null,
                balance: null,
            }
        });

    }catch (error) {
        throw new Error('code : rspf-logout-1012 ' + error.message)
    }
}

export const updateProfile = () => async (dispatch) => {

    try{

        const data = await createRequest('/api/v1/resellers/profile');

        if(data?.header?.code === 20071 && data?.header?.status === 'success'){

            setLocalStorage('user', JSON.stringify(data?.body));

            dispatch({
                type: UPDATE_PROFILE,
                payload: {
                    user: data?.body
                }
            });    

        }else{
            throw new Error(data?.header?.message || 'code : rspf-update-profile-1022');
        }
    }catch (error) {
        throw new Error(error.message);
    }
}