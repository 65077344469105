import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {createRequest, productNameMap} from '../../utils/common';
import Loader from "../layout/Loader";
import { updateProfile } from "../../redux/actions/auth";
import PrivateNavLink from "../layout/PrivateNavLink";
import { Tooltip } from '../Tooltip';
import { Modal, Table, Tabs, Tab } from 'react-bootstrap'

export const CreateAccountForm = ({toast}) => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state?.auth?.user)
    const [loading, setLoading] = useState(true);
    const [hasLowBalance, setHasLowBalance] = useState(false);
    const [showPrices, setShowPrices] = useState(false);
    const [state, setState] = useState({
        products : null,
        addons: null,
        product_type: 'onetime',
        products_data : {},
        billing_cycle: "",      
        product_id: 0,    
        quantity: 1,
        email:"",
        addon_id: null, 
    });

    const productNameMap = {
        monthly: 'Monthly Plan (1 Month)',
        quarterly: 'Quaterly Plan (3 Months)',
        semiannually: 'Semiannually Plan (6 Months)',
        annually: 'Anually Plan (12 Months)',
        biennially: 'Biennially Plan (2 Years)',
        triennially: 'Triennially Plan (3 Years)',
        onetime: 'Lifetime Plan (5 Years)'
    };

    useEffect(() => {
        getCouponProducts();
    }, []);

    useEffect(() => {

        setState({
            ...state,
            billing_cycle : document.getElementById("product").value
        });

    }, [state.product_type]);

    const getCouponProducts = async () => {
        try{

            const data = await createRequest('/api/v1/resellers/products')

            if(data?.header?.code === 50001 && data?.header?.status === 'success') {
                setState({
                    ...state,
                    products_data: data?.body,
                    products: data?.body[state.product_type]?.product,
                    addons: data?.body[state.product_type]?.addon,
                    billing_cycle: Object.keys(data?.body[state.product_type]?.product).filter((val, index) => index === 0)[0],
                    product_id: data?.body[state.product_type]?.product[Object.keys(data?.body[state.product_type]?.product).filter((val, index) => index === 0)[0]]?.id
                });
            }
            else{
                throw new Error(data?.header?.message);
            }

        }catch (error) {
            toast.error(error?.message || "code : rspf-003, Unable to fetch products")
        }

        setLoading(false)
    }

    const productChange = (e) => {

        setState({
            ...state,
            billing_cycle: e.target.value,
            product_id: e.target[e.target.selectedIndex].getAttribute('data-product_id')
        });

    }

    const productTypeChange = (e) => {

        setState({
            ...state, 
            [e.target.name]: e.target.value,
            products: state?.products_data[e.target.value]?.product,
            addons: state?.products_data[e.target.value]?.addon,
            product_id: state?.products_data[e.target.value]?.product[state.billing_cycle] === undefined ? state?.products_data[e.target.value]?.product[Object.keys(state?.products_data[e.target.value].product)[0]].id : state?.products_data[e.target.value]?.product[state.billing_cycle].id,
        });
    }

    const addonChange = (e) => {

        setState({
            ...state,
            addon_id: e.target[e.target.selectedIndex].value == 0 ? null : e.target[e.target.selectedIndex].value
        });

    }

    const emailChange = (e) => {
        setState({
            ...state, 
            [e.target.name]: e.target.value,
        });
    }

    const validateBalance = () => {

        let total_price = +state.products[state.billing_cycle].price * +state.quantity;

        if(total_price > user.balance){

            setHasLowBalance(true);
            throw new Error(`Insufficient Balance! your current balance is: $${user.balance} and transaction amount is: $${total_price}`);
        }

    }

    const checkDedicatedIp = (addon) => {

        return addon.toLowerCase().includes('dedicated') ? addon + ' + Portforwarding (Free)' : addon

    }

    const vpnFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {

            validateBalance();
            
            if(state.email === "") throw new Error("Please enter email");
            const data = {
                quantity : state.quantity,
                billing_cycle : state.billing_cycle,
                product_id : state.product_id,
                billing_type : state.product_type === "onetime" ? 2 : 1,
                email : state.email,
                addon_id : state.addon_id
            }

            const request = await createRequest('/api/v1/resellers/vpn/create', data)

            if(request?.header?.code === 100001 && request?.header?.status === 'success'){
                toast.success(request?.header?.message);

                dispatch(
                    updateProfile()
                ).catch((err) => {
                    toast.error(err.message)
                });

                setState({
                    ...state, 
                    email: "",
                });

            }
            else{   
                toast.error(request?.header?.message || "code : rspf-301, Unable to create coupons")
            }
            
        } catch (error) {
            toast.error(error?.message)
        }
        
        setLoading(false)

    }

    return(
        <Fragment>
            <Loader loading={loading}/>
            <h2 className="component-heading">Create new VPN Account</h2>
            <form className="create-vpn-account" onSubmit={vpnFormSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="product">Select Product:
                            <Tooltip>
                                <>
                                    <div>Select VPN Subscription Billing</div>
                                    <div>Cycle / Tenure for your Users</div>
                                    <p style={{color:'#007bff', cursor:'pointer'}} onClick={() => setShowPrices(true)} >
                                        View pricing for products
                                    </p>
                                </>
                            </Tooltip>
                            </label>
                            <select onChange={productChange} id="product" className="form-control">
                            {state?.products ? 
                                Object.keys(state?.products).map((val, index) => (
                                    <option key={index} value={val} data-product_id={state?.products[val].id} data-billing_cycle={val}> {productNameMap[val]} </option>
                                )) : ""
                            }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="addon">Select Addon:
                                <Tooltip>
                                    <>
                                        <div>Additional Add-on with VPN:</div>
                                        <div>Dedicated IP @{state?.addons ? state?.addons['dedicated_ip_monthly_price'] === undefined ? '1.00' : state?.addons['dedicated_ip_monthly_price'] : "No addon found"}$ per month</div>
                                        <div>Port Forwarding @{state?.addons ? state?.addons['port_forwarding_monthly_price'] === undefined ? '0.25' : state?.addons['port_forwarding_monthly_price'] : "No addon found"}$ per month</div>
                                    </>
                                </Tooltip>
                            </label>
                            <select id="addon" onChange={addonChange} className="form-control">
                                <option value="0"> None </option>
                                {state?.addons ?
                                    typeof state?.addons[state?.billing_cycle] === 'undefined' ? (<option value="0"> No Addons Found against this billing cycle </option>)
                                    :
                                    Object.keys(state?.addons[state?.billing_cycle]).map((val, index) => (
                                        <option key={index} value={state?.addons[state?.billing_cycle][val].id}> {checkDedicatedIp(val)}</option>
                                    )) 
                                    : ""
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Product Type:</label>
                            {/* <div className="form-check">
                                <input type="radio" id="recursive" name="product_type" value="recurring"
                                checked={state.product_type === "recurring"}
                                onChange={productTypeChange}
                                />
                                <label for="recursive">Recursive</label>
                                <Tooltip>
                                    <div>Account once generate will be auto</div>
                                    <div>renewed after billing cycle expires,</div>
                                    <div>also adjusting $ value from the wallet</div>
                                    <div>on renewal</div>
                                </Tooltip>
                            </div> */}
                            <div className="form-check">
                                {/* <input type="radio" name="product_type" id="non-recursive" value="onetime" 
                                checked={state.product_type === "onetime"}
                                onChange={productTypeChange}
                                /> */}
                                <label for="non-recursive">Non-Recursive</label>
                                <Tooltip>One-Time account</Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="user-email">User Email:
                            <Tooltip>
                                <div>Kindly Provide Email id of end-user a</div>
                                <div>VPN account will be issued and</div>
                                <div>welcome email will be send directly.</div>
                            </Tooltip>
                            </label>
                            <input type="email" id="user-email" name="email" className="form-control" value={state.email} onChange={emailChange}/>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group mb-0">
                        {(user.status == 1) ? 
                            <button type="submit" className="theme-gradient-btn">Create VPN Account</button>: null}
                            {hasLowBalance ? 
                                <PrivateNavLink to={'/billing/pay'} className={''} exact={true}>
                                <span style={{color:"#0dac7f", marginLeft:"8px", display:"inline-block", textDecoration:'underline'}}>
                                    Add Fund
                                </span>
                                </PrivateNavLink> : ""
                            }
                        </div>
                    </div>
                </div>
            </form>

            <Modal show={showPrices} onHide={() => setShowPrices(false)}>
                <Modal.Header closeButton closeLabel={'Close'}>
                    <Modal.Title>Product Pricing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(state.products_data).length ?
                        <Tabs
                        defaultActiveKey={"recurring"}
                        transition={true}
                        id="noanim-tab-example"
                        className="mb-3">
                            <Tab eventKey="recurring" title="Recursive" tabClassName="px-2">
                                <Table striped bordered hover>
                                    <thead>
                                            <tr>
                                            <th>Billing Cycle</th>
                                            <th>Price</th>
                                            </tr>
                                    </thead>
                                    {Object.keys(state.products_data['recurring'].product).map((val) => (
                                        <>
                                        <tbody>
                                            <tr>
                                            <td>{productNameMap[val]}</td>
                                            <td>{state.products_data['recurring'].product[val].price}</td>
                                            </tr>
                                        </tbody>
                                        </>
                                    ))}
                                </Table>
                            </Tab>
                            <Tab eventKey="onetime" title="Non-Recursive" tabClassName="px-2">
                                <Table striped bordered hover>
                                    <thead>
                                            <tr>
                                            <th>Billing Cycle</th>
                                            <th>Price</th>
                                            </tr>
                                    </thead>
                                    {Object.keys(state.products_data['onetime'].product).map((val) => (
                                        <>
                                        <tbody>
                                            <tr>
                                            <td>{productNameMap[val]}</td>
                                            <td>{state.products_data['onetime'].product[val].price}</td>
                                            </tr>
                                        </tbody>
                                        </>
                                ))}
                                </Table>
                            </Tab>
                        </Tabs> 
                        : "No product(s) found"
                    }
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}