import React, { useState, useEffect } from 'react'
import { GetPaymentGateways } from '../../services/dashboard/payments/GetPaymentGateways'
import StripeCheckoutForm from '../dashboard/payments/gateways/stripe/CheckoutForm';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import FAQ from '../FAQ'
import { Tooltip } from '../Tooltip';
import AdminRoute from "../layout/PrivateNavLink";
import withReactContent from 'sweetalert2-react-content';
import PAYMENT_CARDS from '../../assets/img/payment_cards.png'
import PAYPAL_IMG from '../../assets/img/paypal_logo_png.png'
import MCAFEE_LOGO from '../../assets/img/ic-mcafee_logo.png'
import PaypalCheckoutForm from '../dashboard/payments/gateways/paypal/CheckoutForm';
import { checkMaxBalance ,createRequest} from '../../utils/common';
import Loader from "../layout/Loader";

const MySwal = withReactContent(Swal);

export const PayInvoice = () => {

    const minAmount = 250;
    const maxAmount = 1000;
    const maxBalance = 1800;
    const user = useSelector((state) => state?.auth?.user);
    const { id } = useParams();
    const [invoice, setInvoice] = useState({});
    const [state, setState] = useState({
        loading: true,
        error: '',
        payment_methods: [],
        active_payment_method: 'stripe',
        amount: "",
        isValid: true,
    });

    const faqs = [
        {
            q: 'How to does the billing works when a coupon is generated when will i be charged?',
            a: 'Coupon $ Value will automatically be adjusted from the wallet once generated.'
        },
        {
            q: 'Does coupon code has an expiry?',
            a: 'Yes, Once generated coupon can be utilized within an year.'
        },
        {
            q: 'What if the code is not utilized /redeemed within a year?',
            a: 'Code once generated will expire in a year, however reseller can delete the code if not redeemed within a year and $ value will be refunded to the wallet.'
        },
        {
            q: 'What if I want to delete the code after it has been redeemed?',
            a: 'Code once redeemed cannot be deleted or refunded. However reseller can update the VPN subscriptions Credientials associated with it from Manage VPN subscription.'
        }
    ];

    const amountValidate = (e) => {
        checkAmount(e.target.value);
    };

    const checkAmount = (amount) => {
        if (amount === '') {
            setState({
                ...state,
                error: "Please enter valid amount",
                isValid: false
            })
            return;
        }
        // else if (amount <= minAmount){
        //     setState({
        //         ...state,
        //         error: "Amount should be greater than $" + minAmount,
        //         isValid: false
        //     })
        //     return;
        // }else if (amount > maxAmount){
        //     setState({
        //         ...state,
        //         error: "Amount should be less than $" + maxAmount,
        //         isValid: false
        //     })
        //     return;
        // }else if (!checkMaxBalance(user.balance, amount, maxBalance)){
        //     setState({
        //         ...state,
        //         error: "Max Balance should be less than or equals to $" + maxBalance,
        //         isValid: false
        //     })
        //     return;
        // }
        else {
            setState({
                ...state,
                error: "",
                isValid: true
            })
            return;
        }
    }

    const inputHandler = (e) => {
        setState({
            ...state,
            [e.currentTarget.name]: e.currentTarget.value
        })
    };

    const paymentGateways = async () => {
        GetInvoice();
        const data = await GetPaymentGateways();

        if (data?.header?.code === 400001 && data?.header?.status === "success") {
            setState({
                ...state,
                payment_methods: data?.body?.payment_methods,
                loading: false
            });
            
        }
        else {
            setState({
                ...state,
                error: data?.header?.message,
                loading: false
            });
        }
    }
    const GetInvoice = async () => {
        let invoiceId = Number(id);

        if (!isNaN(invoiceId)) {

            try {
                const data = await createRequest(`/api/v1/resellers/invoice`, { id: invoiceId });
                if (data?.header?.status === 'success') {
                    setInvoice(data?.body);
                }
                else {
                    throw new Error(data?.header?.message);
                }

            } catch (error) {
                toast.error(error?.message || "code : rspf-003, Unable to fetch invoices");
            }
        }
    }

    const setLoading = (value) => {
        setState({
            ...state,
            loading: value
        });
    }

    const changePaymentMethod = (pay_method) => {
        setState({
            ...state,
            active_payment_method: pay_method
        });
    }

    const p_imgs = {
        stripe: PAYMENT_CARDS,
        paypal: PAYPAL_IMG
    }

    useEffect(() => {
        paymentGateways();
       
    }, []);

    return (
        <div className="component-add_funds">
            <h2 className="component-heading">Pay Invoice No #{id}</h2>
            <p className="heading-slogan">Deposit funds in your wallet to streamline your process, avoid lots of small transactions and automatically let us take care of any new in-&amp;-out transaction that are generated. Funds will help you manage VPN codes and subscriptions easily.</p>
            <Loader loading={state.loading} />
            <div className="w-box fund-box">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <form autoComplete={'off'} onSubmit={(e) => e.preventDefault()}>
                                <div className="form-group">
                                    <label>Amount
                                        <Tooltip>
                                            <div>Minimum Deposit: <strong>${minAmount}.00 USD</strong></div>
                                            <div>Maximum Deposit: <strong>${maxAmount}.00 USD</strong></div>
                                            <div>Maximum Balance: <strong>${maxBalance}.00 USD</strong></div>
                                        </Tooltip>
                                    </label>

                                    <input
                                        id='amount'
                                        onBlur={amountValidate}
                                        type="number"
                                        pattern="[0-9]*"
                                        className="form-control"
                                        name='amount'
                                        value={invoice.total}
                                        onChange={inputHandler}
                                        required={true}
                                        style={{
                                            border: "1px solid #ccc",
                                            padding: "12px"
                                        }}
                                        readOnly
                                    />
                                    <span className="currency_symbol">$</span>
                                    <span className={"errorText " + (state.error ? 'show' : '')} style={{
                                        position: "static"
                                    }}>{state.error}</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Select payment method
                                <Tooltip>
                                    <div style={{ display: 'flex', columnGap: '15px' }}>
                                        <div>
                                            <div>Secure and</div>
                                            <div>Encrypted Payment</div>
                                        </div>
                                        <img src={MCAFEE_LOGO} alt="McAfee Logo" />
                                    </div>
                                </Tooltip>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="payment-method-btns">

                            {state.payment_methods.length ?
                                state.payment_methods.map((val, key) => (
                                    <button
                                        key={key}
                                        id={val.module}
                                        className={`fund-btn ${state.active_payment_method === val.module ? 'active' : ''}`}
                                        onClick={() => changePaymentMethod(val.module)}
                                    >
                                        <span className="dot"></span>
                                        {val.display_name === "Stripe" ? "Pay with card" : val.display_name}
                                        <img className="PM-img" src={p_imgs[val.module]} alt={val.module} />
                                    </button>
                                )) : " Fetching Payment Methods ..."}
                        </div>
                    </div>
                </div>

                {state.payment_methods.length ?
                    <div className="payment_frames mt-4">
                        {state.active_payment_method === 'stripe' ?
                            <div className="row">
                                <div className="col-md-6">
                                    <StripeCheckoutForm checkAmount={checkAmount} error={state.error} isValid={state.isValid} user={user} amount={invoice.total} toast={toast} setLoading={setLoading} loading={state.loading} swal={MySwal} invoiceId={id} invoicePayment={true}/>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-12">
                                    <PaypalCheckoutForm checkAmount={checkAmount} error={state.error} isValid={state.isValid} user={user} amount={invoice.total} toast={toast} setLoading={setLoading} loading={state.loading} swal={MySwal} />
                                </div>
                            </div>
                        }
                    </div>
                    : ''}

                <div className="row mt-4">
                    <div className="col-md-6 d-flex flex-column justify-content-center">
                        <span style={{ color: '#a6a6a6', fontFamily: 'SofiaProLight', fontSize: '14px', textAlign: 'center' }}>By submitting this form you agree to our Terms of service and Privacy Policy.</span>
                    </div>
                    <div className="col-md-6 text-lg-right mcafee-logo">
                        <img src={MCAFEE_LOGO} alt="McAfee Logo" />
                    </div>
                </div>
            </div>

            <FAQ props={faqs} />
        </div>
    )
}