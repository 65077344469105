import React, { useEffect, useState, useRef } from "react";
import pvpnlogo from "../../assets/img/purevpn-reseller-logo.svg";
import { Link } from "react-router-dom";
import NotifyDrop from "../notifications/notifyDrop";
import Bell from "../../assets/img/bell.mp3";
import listenForOutsideClick from '../notifications/listenForOutsideClicks'

//Reactstrap components
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { doLogout, updateProfile } from "../../redux/actions/auth";
import { createRequest, formatCurrency } from "../../utils/common";
import PrivateNavLink from "../layout/PrivateNavLink";
import { toast } from "react-toastify";
import { NewNotification } from "../../redux/actions/notification";

const Header = ({ props, history }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(false);

  const [dropdown, setDropdown] = useState({
    dropdownOpen: false,
  });

  const getNotification = () => {
    return dispatch(NewNotification());
  };

  const logout = () => {
    dispatch(doLogout()).catch((err) => {});
    history.push("login");
    window.Echo.leaveChannel(`reseller-${user_data.id}`);
  };
  try {
    var user_data = useSelector((state) => state?.auth?.user);
    if (user_data === null || typeof user_data === undefined) {
      throw new Error();
    }
  } catch (error) {
    logout();
  }

  const toggle = () => {
    setDropdown({
      dropdownOpen: !dropdown.dropdownOpen,
    });
  };

  const updateBalance = () => {
    setLoading(true);
    dispatch(updateProfile());
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  };

  const DropNotify = () => {
    setNotify(!notify);
  };


  // check outside click
  const menuRef = useRef(null)
  const [listening, setListening] = useState(false)
  useEffect(listenForOutsideClick(listening, setListening, menuRef, setNotify))
  // check outside click

  function playSound(url) {
    const audio = new Audio(url);
    audio.volume = 0.1;
    audio.play();
  }

  useEffect(() => {
    window.Echo.channel(`reseller-${user_data.id}`)
      .listen("Notificationsent", (e) => {
        playSound(Bell);
        getNotification();
      })
      .listen("UpdateBalance", (eventData) => {
        dispatch(updateProfile());
      });

    getNotification();
  }, []);

  const ReadNotification = async (id) => {
    if (!id) {
      id = "";
    }
    await createRequest("/api/v1/resellers/notifications/markread", {
      method: "POST",
      body: id,
    });

    getNotification();
  };

  const newNotificationData = useSelector((state) => state.notification);

  return (
    <div className="main-header">
      {user_data.status == 1 ? null : (
        <div className="activation-bar">
          Your account is deactivated.{" "}
          <Link to={""} className="activate-link">
            Activate
          </Link>{" "}
          your account to perform any action.
        </div>
      )}
      <div className="main-headerPad">
        <div className="row">
          <div className="col-md-6" style={{ alignSelf: "center" }}>
            <div className="header-logo">
              <img src={pvpnlogo} alt="" className="img-fluid" />
            </div>
          </div>
          <div
            className="col-md-6 d-flex justify-content-end"
            style={{ columnGap: "30px" }}
          >
            <div className="notification-icon" onClick={() => DropNotify()} ref={menuRef}>
              <div className="notification-bell">
                {newNotificationData.state.length > 0 ? (
                  <>
                    <span className="notification-count">
                      {newNotificationData.state.length}
                    </span>
                  </>
                ) : null}

                <i className="fa fa-bell"></i>
              </div>
              <>
                <div
                  className="notify-dropdown"
                  style={
                    notify
                      ? {
                          transform: "scaleY(1)",
                          transition: "opacity 300ms, transform 300ms",
                        }
                      : {
                          transform: "scaleY(0)",
                          transition: "opacity 300ms, transform 300ms",
                        }
                  }
                >
                  <div className="notify-drop">
                    <div className="notify-drop-wrap notifications"></div>
                    <div className="unread-bar">
                      {" "}
                      <div className="text unread-bar">
                        <p>Unread Notification</p>
                        <p onClick={() => ReadNotification()}>
                          <span>Mark all as read</span>
                        </p>
                      </div>
                    </div>
                    {newNotificationData.state.map((notification, index) => (
                      <>
                        <div
                          key={index}
                          onClick={() => ReadNotification(notification.uid)}
                        >
                          <NotifyDrop
                            key={notification.uid}
                            message={JSON.parse(notification.data).message}
                            typename={JSON.parse(notification.data).type}
                            date={JSON.parse(notification.data).created_at}
                            redirect={JSON.parse(notification.data).redirect}
                            Id={JSON.parse(notification.data).Id}
                          />
                        </div>
                      </>
                    ))}
                    {newNotificationData.state.length < 1 ? (
                      <div className="no-notification">No New Notification</div>
                    ) : null}
                    <PrivateNavLink to={"/notifications"}>
                      <a
                        style={
                          newNotificationData.state.length < 3
                            ? { bottom: "0" }
                            : {}
                        }
                        className="topbar-collapse-link"
                      >
                        See all Notifications
                      </a>
                    </PrivateNavLink>
                  </div>
                </div>
              </>
            </div>

            <div className="balance-tag">
              {loading ? (
                "Updating..."
              ) : (
                <div className="user-balance">
                  <PrivateNavLink
                    to={"/billing/pay"}
                    className={""}
                    exact={true}
                    activeClassName="active"
                  >
                    <span
                      style={{
                        color: "#ffffff",
                        marginRight: "8px",
                        display: "inline-block",
                      }}
                    >
                      Balance:{" "}
                      <span className="amount">
                        {" "}
                        {formatCurrency(user_data.balance) + "  "}
                      </span>
                    </span>
                  </PrivateNavLink>
                  <i
                    onClick={updateBalance}
                    style={{ cursor: "pointer" }}
                    className="fa fa-refresh"
                  ></i>
                </div>
              )}
            </div>

            <div className="user-dd">
              <Dropdown isOpen={dropdown.dropdownOpen} toggle={toggle}>
                <DropdownToggle>
                  <span className="username-toggle">
                    {" "}
                    {user_data.name} <i className="fa fa-sort-down"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="profile-dropdown">
                  <DropdownItem
                    tag={Link}
                    to={`/${user_data.custom_url}/profile`}
                  >
                    Profile
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={logout}>Log out</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
