import React from 'react';
import LoadingBar from 'react-top-loading-bar';

const Head = (props) => {
    return(
        <LoadingBar
            height={3}
            color='#0088cc'
            progress={props.loading}
        />
    )
};
export default Head