import {getAuthToken} from '../../../utils/common';

export function GetPaymentGateways(){

    return new Promise((resolve,reject) => {
        fetch('/api/v1/payments', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthToken()
            }
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    });
}