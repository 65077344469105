import React, { Fragment, useEffect, useState } from "react";
import { createRequest } from "../../utils/common";
import { Loader } from "../Loader";
import { MDBDataTable } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const List = ({ toast }) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const columns = [
    {
      label: "Coupon",
      field: "coupon",
    },
    {
      label: "Email",
      field: "email",
    },
    {
      label: "VPN Username",
      field: "vpn_username",
    },
    {
      label: "Billing Cycle",
      field: "billing_cycle",
    },
    {
      label: "Billing Type",
      field: "billing_type",
    },
    {
      label: "Addon(s)",
      field: "addons",
    },
    {
      label: "Status",
      field: "status",
    },
    {
      label: "Start Date",
      field: "start_date",
    },
    {
      label: "End Date",
      field: "end_date",
    },
    // {
    //   label: "Actions",
    //   field: "actions",
    // },
  ];

  const view = (id) => {
    console.log(id);
  };

  useEffect(() => {
    const GetVPNList = async () => {
      setLoading(true);

      try {
        const data = await createRequest(`/api/v1/resellers/vpn`);
        if (data?.header?.status === "success") {
          setList({
            columns: columns,
            rows: data?.body?.data.map((e) => {
              //return {...e, clickEvent: () => viewTransaction(e.id), actions: "view"}
              return {
                ...e,
                actions: [
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faEye}
                    onClick={() => view(e.coupon)}
                  />,
                  <FontAwesomeIcon
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    icon={faEye}
                    onClick={() => console.log("Second")}
                  />,
                ],
              };
            }),
          });
        } else {
          toast.error(
            data?.header?.message || "code : rspf-003, Unable to fetch vpn list"
          );
        }
      } catch (error) {
        toast.error(error?.message);
      }

      setLoading(false);
    };

    GetVPNList();
  }, []);

  return (
    <div className="w-box">
      <h2 className="component-heading mb-0">VPN Subscription List</h2>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MDBDataTable
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={list}
            searchBottom={false}
            sortable={false}
            exportToCSV={true}
            noBottomColumns={true}
            entriesLabel="Rows per page"
            className="mt-4"
            barReverse
            hover
            pagingTop
            searchTop
            fullPagination
            responsive = {true}
          />
        </Fragment>
      )}
    </div>
  );
};

export default List;
