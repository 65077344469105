const ExploreDocs = () => {
  return (
    <>
      <div className="pdfCanvas">
        <div className="cs-box minHeight">
          <h2 className="component-heading d-invoicehead">
            <span className="head-line">Coming Soon</span>
          </h2>
        </div>
      </div>
    </>
  );
};

export default ExploreDocs;
