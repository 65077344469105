import Cookies from 'js-cookie';

export const getLocalStorage = function (key, encoded = true) {
    try {

        if (encoded) {
            key = Buffer.from(key).toString('base64');
        }

        let ret = localStorage.getItem(key);

        if (encoded && ret !== null) {
            ret = Buffer.from(ret, 'base64').toString();
        }

        return ret;

    } catch (e) {
        return logout();
        //return <Redirect to={'/login'}/>
    }
};

// have to pass values as strings. int boolean not accepted
export const setLocalStorage = function (key, val, encoded = true) {
    let ret;
    if (encoded) {
        ret = localStorage.setItem(Buffer.from(key).toString('base64'), Buffer.from(val).toString('base64'));
        
    } else {
        ret = localStorage.setItem(key, val);
    }
    return ret;
};

export const removeLocalStorage = function (key, encoded = true) {
    if (encoded) {
        return localStorage.removeItem(Buffer.from(key).toString('base64'));
    } else {
        return localStorage.removeItem(key);
    }
};

export const validateJSON = (string, defaultVal = {}) => {
    try {
        return JSON.parse(string);
    } catch (er) {
        return defaultVal;
    }
}

export const checkAuth = (data, url='/login') => {

    return data.header.status === "forbidden" ? window.location.href=url : null
}

export const hasCsrf = () => {

    if (Cookies.get('XSRF-TOKEN') === undefined){
        fetch('/api/csrf');
    }

}

export const emailValidation = (value) => {

    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value);

}

export const getAuthToken = () => {

    try {
        
        return "bearer " + getLocalStorage('user_data');
    } catch (error) {
        return logout();
   
    }

}

export const logout = (url = 'login') => {

    localStorage.clear();
    window.location.href=url;

};

export function createRequest(endpoint, data = {}, method = 'POST'){

    return new Promise((resolve,reject) => {
        fetch(endpoint, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthToken(),
                'CSRF-Token': Cookies.get('XSRF-TOKEN')
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
          
                if(responseJson?.header?.status === "forbidden"){
                    logout();
                }
                
                resolve(responseJson);
            })
            .catch((error) => {
                if(error instanceof TypeError){
                    logout();
                }
                reject(error);
            });
    });
}

export const capitalize = (string) => {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const checkMaxBalance = (currentBalance, amount, maxBalance) => {
    
    return (currentBalance + +amount) > maxBalance ? false : true

}

export const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
}

export const productNameMap = {
    monthly: 'Monthly Plan (1 Month)',
    quarterly: 'Quaterly Plan (3 Months)',
    semiannually: 'Semiannually Plan (6 Months)',
    annually: 'Annually Plan (12 Months)',
    biennially: 'Biennially Plan (2 Years)',
    triennially: 'Triennially Plan (3 Years)',
    onetime: 'Lifetime Plan (5 Years)'
};

export const validatePassword = (password) => {

    const error_message = "Password must contain minimum 12 characters with atleast 1 number, 1 lowercase, 1 uppercase & 1 special character: !@#$%&^_+{}.,";
    const valid = /^.*(?=.{12,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&^_+{}.,])^(?!.*(UNION)|.*(SELECT)|.*(INSERT)|.*(%0A)|.*(%0D)|.*(%00)|.*(1=1)|.*([<>?*()])).*$/
    if(!valid.test(password)) throw new Error(error_message);
    return;

}