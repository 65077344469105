//React packages
import React,{useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

//Reactstrap components
import Loader from "../layout/Loader";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//React layout
import Head from "../layout/Head";
import LoadingBar from "../layout/LoadingBar";
import logo from '../../assets/img/purevpn-logo-black.png'
import { checkAuth, hasCsrf, emailValidation } from '../../utils/common'

//Rest Api service
import { ForgotPass } from "../../services/ForgotPass";

const MySwal = withReactContent(Swal);

const ForgetPassword = (props) => {

    // Loading bar state
    const [loading, setLoading] = useState(0);
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Will execute after fully loaded
    useEffect(() => {
        setLoading(100)
        hasCsrf();
    }, []);

    // state for form values and handling error
    const [state, setState] = useState({
        email: '',
        loading: false,
        error: '',
        emailError: false,
        showEmail: true,
        visible: true
    });

    // Handling form values and saving it into state
    const handleChange = (e) => {
        setState({
            ...state, [e.target.name]: e.target.value
        });
    };

    // Forgot password form submit
    const submit = async (e) => {

        e.preventDefault();

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            return
        }

        if(emailValidation(state.email)){
            // email validation success
            //  ForgotPass(state)
            setState({ ...state, emailError:false, loading: true });

            const token = await executeRecaptcha('forgot');

            ForgotPass(state, token).then(res => {

                if(res.header.status === "success"){
                    MySwal.fire({
                        title: "<h3>" + res.header.message + "<h3>",
                        icon:'success',
                    });
                    setState({...state, loading:false, email:''});

                }else{
                    setState({...state, loading:false, error: res.header.message, email:'' });
                    MySwal.fire({
                        title: res.header.message,
                        icon:'error',
                        onClose: () => checkAuth(res, 'forgot-password')
                    });
                }
            }).catch((err) => {
                setState({...state, loading:false, error: err, visible: true, password:''});
                toast.error(err.message)
            });
        }else{
            setState({
                ...state, emailError:true
            });
        }
    };

    // Email validation on blur
    const emailValidate = (e) => {
        if(e.target.value === ''){
            setState({...state, emailError:true})
        }else{
            if(emailValidation(e.target.value)){ setState({ ...state, emailError:false }); }
            else{ setState({ ...state, emailError:true }); }
        }
    };

    return(
        <div>
            <Head title={'PureVPN Reseller | Forgot Password'}/>
            <LoadingBar loading={loading}/>
            <ToastContainer />
            {
                state.loading ? <Loader loading={loading}/> : ''
            }
            <div className="accountMain hasBackground">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-3">
                            <div className="accountParent">
                                <div className="accountBox">
                                    <div className="accountHead">
                                        <img src={logo} className="img-responsive" alt="" />
                                        <h3>Forgot Password?</h3>
                                        <p>We'll send you a link to a page where you can easily <br/>
                                            create a new password.
                                        </p>
                                    </div>
                                    <form onSubmit={submit} data-id="1" noValidate className={'reset-form'}>
                                        <div className="accountBody">
                                            <div className={'formBox ' + (state.showEmail ? 'show-email' : 'hide-email')} id="emailBox">
                                                <div className="form-group">
                                                    <input type="email"
                                                           onChange={handleChange}
                                                           className="form-control"
                                                           placeholder="Email address"
                                                           name="email" data-id="1"
                                                           onBlur={emailValidate}
                                                           value={state.email}
                                                    />
                                                    <span className={"errorText " + (state.emailError ? 'show' : '')}>Email address is required</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accountFooter mt-6">
                                            <div className="fullwidthbutton">
                                                <button type="submit" className="theme-gradient-btn" id="forgetPassword">Send reset link</button>
                                            </div>
                                            <div className="clearfix"> </div>
                                        </div>
                                        <div className="mt-5 text-center">
                                            <p >Already have account? <Link to={'login'}>Login</Link></p>
                                            <p >If you don’t have an account? <Link to={'register'} target="_blank">Create account</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ForgetPassword
