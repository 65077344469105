import Cookies from 'js-cookie';

export function doLogin(data, token){

    const {email, password} = data;
    return new Promise((resolve,reject) => {
    fetch('/api/login', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'CSRF-Token': Cookies.get('XSRF-TOKEN'),
        },
        body: JSON.stringify({email, password, token}),
    })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    });
}
