//React packages
import React, { useState, useEffect, Fragment } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTag, faShieldAlt, faReceipt, faFileInvoiceDollar, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import PrivateNavLink from '../layout/PrivateNavLink';
import { useSelector, useDispatch } from 'react-redux';


const Navbar = ({ props, history }) => {
    const user_data = useSelector((state) => state?.auth?.user);
    const [state, setState] = useState('dashboard');

    const [profileDropdown, setprofileDropdown] = useState({
        dropdownOpen: false
    });

    // Active router Link
    const [active, setActive] = useState('dashboard');

    // Hover on sidebar but right now its disabled
    const [hoverSidebar, setHoverSidebar] = useState(false);

    //Sidebar Toggle
    const [toggleState, setToggleState] = useState(false);

    // Change in navigation
    const changeNav = (e) => {

        e.stopPropagation();
        setState(e.currentTarget.id);
        setActive(e.currentTarget.id);
    };

    // Profile image toggle
    const profileToggle = () => {
        setprofileDropdown({
            dropdownOpen: !profileDropdown.dropdownOpen
        })
    };

    // All dashboard links
    const links = () => {
        switch (state) {
            case 'admin':
                return (
                    <div>
                        <div className="sidebar-right-head">
                            <h4>Admin</h4>
                        </div>
                        <div className="sidebar-right-links">
                            <ul className="list-unstyled">
                                {user_data.is_admin ? <li>
                                    <PrivateNavLink to={'/users/all'} className={''} exact={true} activeClassName='active'>Users</PrivateNavLink>
                                </li> : ''
                                }
                            </ul>
                        </div>
                    </div>
                )
            case 'dashboard':
                return (
                    <div>
                        <div className="sidebar-right-head">
                            <h4>Control Panel</h4>
                        </div>
                        <div className="sidebar-right-links">
                            <ul className="list-unstyled">
                                <li>
                                    <PrivateNavLink to={'/dashboard/overview'} className={''} exact={true} activeClassName='active'>Overview</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/coupons/generate'} className={''} exact={true}>Generate Coupon</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/vpn/subscriptions'} className={''} exact={true}>Manage VPN Subscriptions</PrivateNavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            case 'coupons':
                return (
                    <div>
                        <div className="sidebar-right-head">
                            <h4>Coupons</h4>
                        </div>
                        <div className="sidebar-right-links">
                            <ul className="list-unstyled">
                                <li>
                                    <PrivateNavLink to={'/coupons/generate'} className={''} exact={true} activeClassName='active'>Generate Coupons</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/coupons/list'} className={''} exact={true}>Coupons List</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/coupons/price'} className={''} exact={true}>Product Pricing</PrivateNavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                )

            case 'vpn-mgmt':
                return (
                    <div>
                        <div className="sidebar-right-head">
                            <h4>Manage VPN</h4>
                        </div>
                        <div className="sidebar-right-links">
                            <ul className="list-unstyled">
                                <li>
                                    <PrivateNavLink to={'/vpn/subscriptions'} className={''} exact={true} activeClassName='active'>Manage Subscriptions</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/vpn/subscriptions/create'} className={''} exact={true} activeClassName='active'>Create Account</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/vpn/subscriptions/update'} className={''} exact={true} activeClassName='active'>Update Account</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/vpn/subscriptions/enable'} className={''} exact={true} activeClassName='active'>Enable/Disable Account</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/vpn/subscriptions/delete'} className={''} exact={true} activeClassName='active'>Delete Account</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/vpn/subscriptions/list'} className={''} exact={true} activeClassName='active'>VPN List</PrivateNavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                )

            case 'billing':
                return (
                    <div>
                        <div className="sidebar-right-head">
                            <h4>Billing &amp; History</h4>
                        </div>
                        <div className="sidebar-right-links">
                            <ul className="list-unstyled">
                                <li>
                                    <PrivateNavLink to={'/billing/pay'} className={''} exact={true} activeClassName='active'>Add Funds</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/billing/unpaid/invoices'} className={''} exact={true} activeClassName='active'>Unpaid Invoices</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/billing/invoices'} className={''} exact={true} activeClassName='active'>My Invoices</PrivateNavLink>
                                </li>
                                <li>
                                    <PrivateNavLink to={'/billing/transactions'} className={''} exact={true} activeClassName='active'>Transaction History</PrivateNavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            case 'Generate':
                return (
                    <div>
                        <div className="sidebar-right-head">
                            <h4>Integration</h4>
                        </div>
                        <div className="sidebar-right-links">
                            <ul className="list-unstyled">
                                <li>
                                    <PrivateNavLink to={'/integration/form'} className={''} exact={true} activeClassName='active'>Form</PrivateNavLink>
                                </li>
                                {/* <li>
                                    <PrivateNavLink to={'/dashboard/generateApi'} className={''} exact={true}>API</PrivateNavLink>
                                </li> */}

                            </ul>
                        </div>
                    </div>)
            default: return '';
        }
    };

    const hoverNav = (e) => {
        if (toggleState === true) {

            setState(e.currentTarget.id);
            setHoverSidebar(true);
            setActive(e.currentTarget.id);

        } else {

        }
    }
    const disableHoverNav = (e) => {
        setHoverSidebar(false);
    }

    const sidebarToggle = () => {
        setToggleState(!toggleState)
    }

    //Active state remain on page refresh
    useEffect(() => {

        //Set sidebar active on page reload
        if (/users/.test(window.location.href) || /user/.test(window.location.href)) {
            setState('admin');
            setActive('admin');
        } else if (/dashboard\/overview/.test(window.location.href)) {
            setState('dashboard');
            setActive('dashboard');
        } else if (/coupons/.test(window.location.href)) {
            setState('coupons');
            setActive('coupons');
        } else if (/vpn/.test(window.location.href)) {
            setState('vpn-mgmt');
            setActive('vpn-mgmt');
        } else if (/billing/.test(window.location.href)) {
            setState('billing');
            setActive('billing');
        } else if (/vpn/.test(window.location.href)) {
            setState('vpn-mgmt');
            setActive('vpn-mgmt');
        } else if (/payments/.test(window.location.href)) {
            setState('payments');
            setActive('payments');
        } else if (/coupons/.test(window.location.href)) {
            setState('coupons');
            setActive('coupons');
        } else if (/developer/.test(window.location.href)) {
            setState('developer');
            setActive('developer');
        } else if (/business-policies/.test(window.location.href)) {
            setState('business-policies');
            setActive('business-policies');
        } else if (/settings/.test(window.location.href)) {
            setState('settings');
            setActive('settings');
        } else if (/reports/.test(window.location.href)) {
            setState('analytics');
            setActive('analytics');
        } else if (/Generate/.test(window.location.href)) {
            setState('Generate');
            setActive('Generate');
        }
        else {

        }
    }, []);

    return (
        <Fragment>
            <div className={'sidebar ' + (toggleState ? 'sidebar-toggled' : '')}>

                <div className="sidebar-toggle">
                    <button onClick={sidebarToggle}><i className="fa fa-align-justify fa-bars"> </i></button>
                </div>
                <div className="sidebar-body">
                    <div className="sidebar-left">
                        <div className="sidebar-left-start">
                            <div className="sidebar-icons">
                                <ul className="list-unstyled">
                                    {user_data.is_admin ?
                                        <li>
                                            <a onClick={changeNav} onMouseEnter={hoverNav} id="admin" className={active === "admin" ? 'active' : ''} >
                                                <FontAwesomeIcon icon={faUserTag} />
                                            </a>
                                        </li>
                                        : ''}
                                    <li>
                                        <a onClick={changeNav} onMouseEnter={hoverNav} id="dashboard" className={active === "dashboard" ? 'active' : ''} >
                                            <i className="fa fa-home fa-lg" style={{ color: 'white' }} aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={changeNav} onMouseEnter={hoverNav} id="coupons" className={active === "coupons" ? 'active' : ''} >
                                            <FontAwesomeIcon icon={faUserTag} />
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={changeNav} onMouseEnter={hoverNav} id="vpn-mgmt" className={active === "vpn-mgmt" ? 'active' : ''} >
                                            <FontAwesomeIcon icon={faShieldAlt} />
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={changeNav} onMouseEnter={hoverNav} id="billing" className={active === "billing" ? "active" : ''} >
                                            <FontAwesomeIcon icon={faFileInvoiceDollar} size='2x' />
                                        </a>
                                    </li>

                                    <li>
                                        <a onClick={changeNav} onMouseEnter={hoverNav} id="Generate" className={active === "Generate" ? "active" : ''} >
                                            <FontAwesomeIcon icon={faShareAlt} size='2x' />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="sidebar-left-end text-center">
                            <img src={logo} alt="" className={'img-fluid'} style={{ maxWidth: "40px", width: "100%" }} />
                        </div>
                    </div>
                    <div className={'sidebar-right ' + (hoverSidebar ? 'hover-active' : '')} onMouseLeave={disableHoverNav}>
                        <div className="sidebar-right-start">
                            {links()}
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>

    );
}

export default withRouter(Navbar);