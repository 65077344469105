import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getLocalStorage} from "../utils/common";

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props=>
            getLocalStorage('user_data') ?
                (
                    <Component {...props}/>
                )
                :
                <Redirect
                    to={{ pathname:'/login', state: {from: props.location} }}
                />
        }
    />
)