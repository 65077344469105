//React packages
import React,{useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

//Rest Api service
import { ResetPasswordVerification } from "../../services/ResetPasswordVerification";
import { UpdatePasswordForm } from "../forms/UpdatePasswordForm";

//React layout
import Head from "../layout/Head";
import LoadingBar from "../layout/LoadingBar";
import Loader from "../layout/Loader";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { hasCsrf, checkAuth } from '../../utils/common'

const MySwal = withReactContent(Swal);

const ResetPassword = (props) => {
    // Loading bar state
    const [loading, setLoading] = useState(0);
    const [hash, setHash] = useState(props.match.params.hash);
    const { executeRecaptcha } = useGoogleReCaptcha();
    
    // Will execute after fully loaded
    useEffect(() => {

        if(hash.length < 15){
            props.history.push(`/login`);
        }

        setLoading(100);
        hasCsrf();

    }, []);
    

     // Will execute after fully loaded
     useEffect(() => {

        verifyHash();

    }, [executeRecaptcha]);

    // state for form values and handling error
    const [state, setState] = useState({
        loading: true,
        error: '',
        visible: true,
        message: "Verifying....",
        isValidHash: false,
        client_id: 0
    });

    const toggleLoading = (value) => {

        setState({...state, loading:value})

    }

    // Login Form Submit
    const verifyHash = async () => {

        setState({ ...state, loading: true});

        if (!executeRecaptcha) {
            return false;
        }

        const token = await executeRecaptcha('password');

        ResetPasswordVerification(hash, token).then(res => {

            if(res.header.status === "success"){
                setState({...state, loading:false, isValidHash: true, client_id:res?.body?.client_id });

            }else{
                setState({...state, loading:false, error: res.header.message, message: res.header.message});
                MySwal.fire({
                    title: res.header.message,
                    icon:'error',
                    onClose: () => checkAuth(res)
                });
            }
        }).catch((err) => {
            setState({...state, loading:false, error: err, visible: false});
            toast.error(err.message)
        });
    };

    return(
        <div>
            <Head title={'PureVPN Reseller | Reset Password'}/>
            <LoadingBar loading={loading}/>
            <ToastContainer />
            {
                state.loading ? <Loader loading={loading}/> : ''
            }
            <div className="accountMain hasBackground">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-3">
                            <div className="accountParent">
                                {state.isValidHash && state.client_id ?  <UpdatePasswordForm client_id={state.client_id} MySwal={MySwal} loading={toggleLoading} toast={toast} /> : 
                                <div className="text-center">
                                    <h3>{state.message}</h3>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ResetPassword
