import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getLocalStorage} from "../utils/common";

export const PublicRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props=>
            getLocalStorage('user_data') ?
                (
                    <Redirect
                        to={{ pathname:'/dashboard', state: {from: props.location} }}
                    />

                )
                :
                <Component {...props}/>
        }
    />
)