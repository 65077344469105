import React, {useState} from "react";
import { CardElement, useStripe, useElements, } from "@stripe/react-stripe-js";
import { createRequest } from "../../../../../utils/common";
import {faLock} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

const CheckoutForm = ({ checkAmount, error, user, isValid, amount, toast, setLoading, swal ,invoiceId=0,invoicePayment=false}) => {

    const [name, setName] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const amountWithCurrency = amount !== ''? `$${amount}` : '';
    const history = useHistory();

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                lineHeight: "1.7",
                color: "#303238",
                fontSize: "16px",
                fontFamily: "sans-serif",
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#CFD7DF"
                }
            },
            invalid: {      
                color: "#e5424d",
                ":focus": {
                    color: "#303238"
                }
            }
        }
    };
  
    const nameChangeHandler = (e) => {

      setName(e.target.value);

    }

    const handleSubmit = async (event) => {

      event.preventDefault();
      checkAmount(amount)
      
      if(!isValid){

        toast.error(error === "" ? "Please enter valid amount" : error);
        return;

      }
      
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      try {

        if(name === ""){throw new Error("Name is required.")} 

        setLoading(true);

        const card = elements.getElement(CardElement);
        const data = await stripe.createToken(card);

        if(data?.error){

          setLoading(false);
          throw new Error(data?.error?.code +" "+ data?.error?.message);

        }

        const params = { name:name, email:user.email, amount, token: data?.token?.id, invoiceId:invoiceId, invoicePayment:invoicePayment,}

        const result = await createRequest('/api/v1/payments/stripe', params).catch((e) => {
            throw new Error(e?.message)
        });

        if(result?.header?.code === 400002){

            const payload = await stripe.confirmCardPayment(result?.body?.client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement)
                },
            });

            if (payload?.error) {

                throw new Error(payload?.error?.message + " : " + payload?.error?.decline_code)
            }

            setLoading(false);

            // swal.fire({
            //     title: "Payment has been successfull.",
            //     icon:'success',
            // });
            history.push(`../payments/success`)

        }else{

            throw new Error(result?.header?.message)

        }
          
      } catch (error) {
        setLoading(false);
        toast.error(error?.message);
          
      }
      
      
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <label>Name</label>
        <input type="text" name="name" value={name} onChange={nameChangeHandler} className="form-control" style={{ border: "1px solid #ccc",padding: "12px"}}/>
        <label>Card details</label>
        <CardElement options={CARD_ELEMENT_OPTIONS} className="stripe-input-styles form-control" />
        
        <div className="mt-4 secure-box pay-btn" >
            <button disabled={!stripe} type="submit" className="theme-gradient-btn">Pay {amountWithCurrency}</button>
            <div className="secure_payment_slogan d-inline-block ml-5">
                <FontAwesomeIcon icon={faLock} color="#15aabf" /> <span style={{color:'#a6a6a6',fontFamily:'SofiaProLight'}}>Secure and encrypted payments</span>
            </div>
        </div>
      </form>
    );
};


export default CheckoutForm