import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCheck, faTimes, faEye } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { createRequest } from "../../utils/common";
import { updateProfile } from "../../redux/actions/auth";
import { Loader } from "../Loader";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const EditableDiv = ({ field, closeFunc, saveFunc }) => {

    return (
        <div style={{ position: "absolute", textAlign: "right", width: "100%" }}>
            <FontAwesomeIcon title="Save" icon={faCheck} size={"xs"} className="mr-2" style={{ cursor: "pointer" }} color={"#28a745"} onClick={() => saveFunc(field)} />
            <FontAwesomeIcon title="Cancel" icon={faTimes} size={"xs"} style={{ cursor: "pointer" }} color={"#dc3545"} onClick={closeFunc} />
        </div>
    )
}
function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Change Password
                </Modal.Title>
            </Modal.Header>
            <form>
            <Modal.Body>
                <label data-label="password">Old Password</label>
                <input type="password" name="old_password" className="form-control" autoComplete="new-password" value={props.state.old_password} onChange={(e) => { props.textInputHandler(e) }}  />
                <label data-label="password">New Password</label>
                <input type="password" name="password" className="form-control" autoComplete="off" value={props.state.password}  onChange={(e) => { props.textInputHandler(e) }} />
                <label data-label="password">Confirm Password</label>
                <input type="password" name="password_confirmation" className="form-control" autoComplete="off" value={props.state.password_confirmation}  onChange={(e) => { props.textInputHandler(e) }} />
            </Modal.Body>
            <Modal.Footer>
                <Button className="theme-gradient-btn" onClick={() => props.saveFunc('password')}>Update</Button>
                <Button variant="danger"  onClick={props.onHide}>Close</Button>
            </Modal.Footer>
            </form>
        </Modal>
    );
}

const Profile = ({ toast }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const user_data = useSelector((state) => state?.auth?.user);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState('');

    const [state, setState] = useState({
        name: user_data.name,
        email: user_data.email,
        status: user_data.status,
        password: '',
        old_password: '',
        password_confirmation:''
    });

    const [fieldsProps, setFieldsProps] = useState({
        name: false,
        email: false,
        password: false,
        status: false
    })

    const styleCSS = {
        faEyeStyle: {
            position: "absolute",
            bottom: "15px",
            right: "10px",
            cursor: "pointer"
        },
        disableSelectCSS: {
            WebkitAppearance: "none",
            MozAppearance: "none"
        },
        faEye: {
            cursor: 'pointer'
        }
    }

    const saveRequestHandler = async (field) => {

        try {

            if (state[field] === user_data[field] && field !== 'password') {
                throw new Error('Nothing to update');
            }

            if (state[field] === '') {
                throw new Error('Field is empty');
            }

            setLoading(true);

            let url = "/api/v1/resellers/update";
            let postData={ 'update': field, 'value': state[field] };
            if (state.password) {
                if (!executeRecaptcha) {
                    return
                }
                const token = await executeRecaptcha('register');
                console.log(state.password,state.old_password,state.password_confirmation);
                url = "/api/password/change";
                postData={ 
                    'client_id': user_data.client_id,
                    'old_password': state['old_password'],
                    'password': state['password'],
                    'password_confirmation': state['password_confirmation'],
                    'token' : token
                 };
            }
            const data = await createRequest(url,postData );
            
            if (data.header.status === 'success') {

                toast.success(`${field} updated successfully.`);
                setFieldsProps({
                    ...fieldsProps,
                    [field]: false
                })

                dispatch(updateProfile())

            } else {
                throw new Error(data.header.message || "Unable to update, Please try again");
            }


        } catch (error) {

            toast.error(error.message);

        }
        finally{
        if (field === 'password') {
            setModalShow(false);
            setState({
                ...state,
                password: '',
                old_password: '',
                password_confirmation: ''
            })
        }
        setLoading(false);
        }
    }

    const textInputHandler = (e) => {
        console.log(e.target.value,e.target.name);
        setState({
            ...state,
            [e.target.name]: e.target.value
        })

    }

    const statusHandler = (e) => {

        setState({

            ...state,
            status: e.target[e.target.selectedIndex].value

        });

    }

    const closeEditable = (e) => {

        const label = e.target.parentElement.parentElement.parentElement.querySelector('label').attributes['data-label'].value;
        setFieldsProps({
            ...fieldsProps,
            [label]: false
        })
    }

    const getAmount = async () => {

        try {

            setLoading(true);

            const data = await createRequest('/api/v1/resellers/amount');

            if (data.header.status === 'success') {

                setAmount(data?.body?.amount)

            } else {
                throw new Error(data.header.message || "Unable to update, Please try again");
            }


        } catch (error) {

            toast.error(error.message);

        }
        setLoading(false);


    }

    const editField = (e) => {
        const parent = e.target.parentElement.parentElement;
        const label = parent.querySelector('label').attributes['data-label'].value;
        switch (label) {
            case "password":
                setFieldsProps({
                    ...fieldsProps,
                    password: true
                })

                break;
            case "status":
                setFieldsProps({
                    ...fieldsProps,
                    status: true
                })
                parent.classList.add('editable');
                parent.querySelector('select').disabled = false;
                parent.querySelector('select').removeAttribute('readOnly')
                break;
            default:
                setFieldsProps({
                    ...fieldsProps,
                    [label]: true
                })
                break;
        }
    }
    //


   

    //

    return (
        <>
            <h2 className="component-heading">Profile</h2>
            {loading ? <Loader /> :
                <div className="w-box">
                    <form className="edit-profile-details" autoComplete="off">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Name</label>
                                    <input type="text" id="profile_name" onChange={(e) => { textInputHandler(e) }} name="name" className="form-control" value={state.name} readOnly={!fieldsProps.name ? 'readOnly' : ''} />

                                    {fieldsProps.name ?
                                        <EditableDiv field={"name"} closeFunc={closeEditable} saveFunc={saveRequestHandler} />
                                        : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="email">Email / Username</label>
                                    <input type="text" id="profile_email" onChange={(e) => { textInputHandler(e) }} name="email" className="form-control" value={state.email} autoComplete="off" readOnly={!fieldsProps.email ? 'readOnly' : ''} />

                                    {fieldsProps.email ?
                                        <EditableDiv field={"email"} closeFunc={closeEditable} saveFunc={saveRequestHandler} />
                                        : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="password">Password</label>
                                    <Button className="theme-gradient-btn" onClick={() => setModalShow(true)}>
                                        Change Password
                                    </Button>

                                    <MyVerticallyCenteredModal
                                        show={modalShow} textInputHandler={textInputHandler} state={state}
                                        onHide={() => setModalShow(false)} saveFunc={saveRequestHandler}
                                    />
                                    {/* <input type="password" id="profile_password" onChange={(e) => { textInputHandler(e) }} name="password" className="form-control" autoComplete="off" readOnly={!fieldsProps.password ? 'readOnly' : ''} />

                                    {fieldsProps.password ?
                                        <EditableDiv field={"password"} closeFunc={closeEditable} saveFunc={saveRequestHandler} />
                                        // <BTModal />
                                        : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                    } */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="status">Status</label>
                                    <select id="profile_status" name="status" className="form-control" style={styleCSS.disableSelectCSS} value={state.status} onChange={statusHandler} readOnly={!fieldsProps.status ? 'readOnly' : ''} disabled={!fieldsProps.status ? 'disabled' : ''} >
                                        <option value={1}>Active</option>
                                        <option value={2}>Inactive</option>
                                    </select>

                                    {fieldsProps.status ?
                                        <EditableDiv field={"status"} closeFunc={closeEditable} saveFunc={saveRequestHandler} />
                                        : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label>Total Amount Credited</label>
                                    {amount === "" ? <FontAwesomeIcon style={styleCSS.faEye} icon={faEye} onClick={getAmount} /> : <input type="text" value={amount} name="total_amount" className="form-control" readOnly={'readOnly'} />}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default Profile;