import LoadingBar from "../layout/LoadingBar";
import { hasCsrf, validatePassword } from '../../utils/common'
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import Head from "../layout/Head";
import logo from '../../assets/img/purevpn-logo-black.png';
import { doRedeem } from "../../services/Redeem";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const MySwal = withReactContent(Swal);

const Redeem = () => {

    const [loading, setLoading] = useState(0);
    const [check, setCheck] = useState(false);
    const [addons, setAddons] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [addonId, setAddonId] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [state, setState] = useState({
        
        name:'',
        email: '',
        password: '',
        password_confirmation:'',
        coupon:'',
        loading:false
        
    });

    // const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) =>{

        setState({
            ...state, [e.target.name]: e.target.value
        });

    };

    const addonChange = (e) => {
        setAddonId(e.target.value);
        
    }

    const handleSubmit = async (e) =>{

        e.preventDefault();
        //setFormErrors(validate(state));
        if (!executeRecaptcha) {
            return
        }
        
        try {
            
            setState({...state, loading:true})
            validate();
            const token = await executeRecaptcha('redeem');
            await doRedeem({...state, addon_id:addonId}, token).then(res => {

                if(res.header.status === "actionrequired"){
                    
                    setAddons(res.body.addons);
                    setAddonId("0");
                    toast.success(`Please select ${res.header.message}`);
                    return;
                    
                }

                if(res.header.status === "success"){
                    
                    MySwal.fire({
                        title: res.header.message,
                        icon:'success',
                        onClose: () => window.location.href = '/redeem'
                    });

                }else{

                    MySwal.fire({
                        title: res.header.message,
                        icon:'error',
                    });

                }
                
                
            });
            
        } catch (error) {

            toast.error(error.message)
            
        }

        setState({...state, loading:false})

    };

    const validate = () =>{

        if(!state.name) throw new Error('Name is Required!');
        if(!state.email) throw new Error('Email is Required!');
        if(!state.password) throw new Error('Password is Required!');
        if(state.password !== state.password_confirmation) throw new Error("Passwords don't match!");
        validatePassword(state.password)
        if(!state.coupon) throw new Error('Coupon is Required!');
        if(!check) throw new Error('Please check Privacy Policy & Terms of Usage');
        if(addonId === "0") throw new Error('Please select Dedicated IP');
    };

    useEffect(() => {
        setLoading(100)
        hasCsrf();
    }, []);

    return(
              
            <div>
            <Head title={'PureVPN | Redeem'}/>
            <LoadingBar loading={loading}/>
            <ToastContainer />
            {
                state.loading
                    ?
                    <div className={'dashboard-inner-loader ml-0 ' + (loading ? 'show' : 'hide')}>
                        <div className="sk-chase">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>
                    </div>
                    :
                        ''
            }
            <div className="accountMain hasBackground">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-3 redeem-box">
                            <div className="accountParent">
                                <div className="accountBox">
                                    <div className="accountHead">
                                        <img src={logo} className="img-responsive" alt="" />
                                        <h3>Welcome To PureVPN</h3>
                                        <p>Get Started With Your Subscription By Activating Your Coupon Code</p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="accountBody">
                                           <div className="Cbox">

                                            <div className="r-field">
                                                <div className="form-group">
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Please Enter Your Name"
                                                           name="name"
                                                           value={state.name}
                                                           onChange={handleChange}
                                                           disabled={addons.length}
                                                    />  
                                                    {/* <span className="r-error">{formErrors.name}</span> */}
                                                </div>
                                            </div>

                                            <div className="r-field">
                                                <div className="form-group">
                                                    <input type="email"
                                                           className="form-control"
                                                           placeholder="Please Enter Your Email Address"
                                                           name="email"
                                                           value={state.email}
                                                           onChange={handleChange}
                                                           disabled={addons.length}
                                                    />
                                                    {/* <span className="r-error">{formErrors.email}</span> */}
                                                </div>
                                            </div>

                                            </div>
                                            <div className="Cbox">
                                            <div className="r-field">
                                                <div className="form-group">
                                                    <input type={showPassword ? "text" : "password" }
                                                           className="form-control"
                                                           placeholder="Password"
                                                           name="password"
                                                           minLength="3"
                                                           value={state.password}
                                                           onChange={handleChange}
                                                           disabled={addons.length}
                                                    />
                                                    {/* <span className="r-error">{formErrors.password}</span> */}
                                                    <FontAwesomeIcon style={{cursor: 'pointer', float: 'right', position: 'relative', marginTop: '-30px', marginRight : '8px'}} icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)}/>
                                                </div>
                                            </div>
                                            <div  className="r-field">
                                                <div className="form-group">
                                                    <input type={showPassword ? "text" : "password" }
                                                           className="form-control"
                                                           placeholder="Confirm Password"
                                                           name="password_confirmation"
                                                           value={state.password_confirmation}
                                                           onChange={handleChange}
                                                           disabled={addons.length}
                                                    />
                                                    {/* <span className="r-error">{formErrors.password_confirmation}</span> */}
                                                   
                                                </div>
                                            </div>
                                            </div>
                                            <div className="Cbox">
                                                <div  id="passwordBox" className="r-field">
                                                    <div className="form-group">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder="Coupon"
                                                            name="coupon"
                                                            value={state.coupon}
                                                            onChange={handleChange}
                                                            disabled={addons.length}
                                                            
                                                        />
                                                        {/* <span className="r-error">{formErrors.coupon}</span> */}
                                                    </div>
                                                </div>

                                                <div className="r-field">
                                                <div className="form-group">
                                                    {addons.length ?
                                                    
                                                        <select onChange={addonChange} className="form-control">  
                                                        <option value="0"> Select Dedicated IP </option>
                                                            {addons.map((val, i) => ( 
                                                                <option key={i} value={val.id}> {val.name} </option>
                                                            ))}

                                                        </select> : ""
                                                    }
                                                    {addonId === "0" ? <span className="r-error">Please Select Dedicated IP</span> : ""}
                                                </div>
                                            </div>

                                            </div>
                                            
                                            
                                        </div>
                                        <div className="accountFooter">
                                            {/* <span className="r-error">{formErrors.check}</span> */}
                                            <div className="r-terms">
                                                <input onClick={() => setCheck(!check)} type="checkbox" name="terms"/>
                                                <label> I have read and understood the <a href="https://www.purevpn.com/privacy-policy.php">Privacy Policy</a> & <a href="https://www.purevpn.com/term.php">Terms of Usage.</a></label>
                                            </div>
                                            <div>
                                                <button className="r-btn" type="submit" id="accountNext">Activate Account</button>
                                            </div>
                                                {/* <span className="r-error">{formErrors.check}</span> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default Redeem;