import React from 'react';
import fourofour from '../assets/img/404.png';

const NotSupported = () => {
    return (
        <div className="not-supported-wrapper">
            <div className={'ei-parent'}>
                <img src={fourofour} alt="Browser not supported"/>
                <div className="ei-text">
                    <h4 className={'text-capitalize'}>Browser not supported</h4>
                    <p>Please update your browser</p>
                </div>
            </div>
        </div>
    )
}

export default NotSupported