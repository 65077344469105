import Cookies from 'js-cookie';

export function ForgotPass(data, token){
    const {email} = data;
    return new Promise((resolve,reject) => {
        fetch('/api/password/forgot', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'CSRF-Token': Cookies.get('XSRF-TOKEN'),
            },
            body: JSON.stringify({email, token}),
        })
        .then((response) => response.json())
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    })
}