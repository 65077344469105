import Cookies from 'js-cookie';

export function RegisterService(data, token){
   
    const {email, name, password, password_confirmation} = data;

    return new Promise((resolve,reject) => {
        fetch('/api/register', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'CSRF-Token': Cookies.get('XSRF-TOKEN'),
            },
            body: JSON.stringify({email, name, password, password_confirmation, token}),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                resolve(responseJson);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
