import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faCheck, faTimes, faEye } from "@fortawesome/free-solid-svg-icons";
import AdminRoute from "../layout/PrivateNavLink";
import { useSelector, useDispatch } from 'react-redux';
import { createRequest } from "../../utils/common";
import { updateProfile } from "../../redux/actions/auth";
import { Loader } from "../Loader";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const EditableDiv = ({ field, closeFunc, saveFunc }) => {

    return (
        <div style={{ position: "absolute", textAlign: "right", width: "100%" }}>
            <FontAwesomeIcon title="Save" icon={faCheck} size={"xs"} className="mr-2" style={{ cursor: "pointer" }} color={"#28a745"} onClick={() => saveFunc(field)} />
            <FontAwesomeIcon title="Cancel" icon={faTimes} size={"xs"} style={{ cursor: "pointer" }} color={"#dc3545"} onClick={closeFunc} />
        </div>
    )
}
function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Change Password
                </Modal.Title>
            </Modal.Header>
            <form>
                <Modal.Body>
                    <label data-label="password">New Password</label>
                    <input type="password" name="password" className="form-control" autoComplete="off" value={props.state.password} onChange={(e) => { props.textInputHandler(e) }} />
                    <label data-label="password">Confirm Password</label>
                    <input type="password" name="password_confirmation" className="form-control" autoComplete="off" value={props.state.password_confirmation} onChange={(e) => { props.textInputHandler(e) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="theme-gradient-btn" onClick={() => props.saveFunc('password')}>Update</Button>
                    <Button variant="danger" onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
function SendInvoiceToResellerModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Send Invoice
                </Modal.Title>
            </Modal.Header>
            <form>
                <Modal.Body>
                    <label data-label="password">Amount</label>
                    <input type="text" name="invoice_amount" className="form-control" autoComplete="off" value={props.state.invoice_amount} onChange={(e) => { props.textInputHandler(e) }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="theme-gradient-btn" onClick={() => props.saveFunc('invoice_amount')}>Send</Button>
                    <Button variant="danger" onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}


const Profile = ({ toast }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [invoiceModalShow, setinvoiceModalShow] = React.useState(false);
    const user_data = useSelector((state) => state?.auth?.user);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const { id } = useParams();
    const [reseller, setReseller] = useState({});
    // const [resellerPricing, setresellerPricing] = useState({});
    const [state, setState] = useState({
        id: '',
        name: '',
        email: '',
        status: '',
        clientId: '',
        balance: '',
        amountIn: 0,
        amountOut: 0,
        password: '',
        password_confirmation: '',
        recuringPricing: {
            monthly: '',
            quarterly: '',
            semiannually: '',
            annually: '',
            biennially: '',
            triennially: '',
        }
    });

    const [fieldsProps, setFieldsProps] = useState({
        name: false,
        email: false,
        password: false,
        status: false,
        amountIn: false,
        amountOut: false,
        recmonthly: false,
        recquarterly: false,
        recsemiannually: false,
        recannually: false,
        recbiennially: false,
        rectriennially: false,
        monthly: false,
        quarterly: false,
        semiannually: false,
        annually: false,
        biennially: false,
        triennially: false,
    })

    const styleCSS = {
        faEyeStyle: {
            position: "absolute",
            bottom: "15px",
            right: "10px",
            cursor: "pointer"
        },
        disableSelectCSS: {
            WebkitAppearance: "none",
            MozAppearance: "none"
        },
        faEye: {
            cursor: 'pointer'
        }
    }
    const billingCycleNames = {
        "monthly": "Monthly Plan (1 Month)",
        "quarterly": "Quaterly Plan (3 Month)",
        "semiannually": "Semiannually Plan (6 Month)",
        "annually": "Annually Plan (12 Month)",
        "biennially": "Biennially Plan (2 Years)",
        "triennially": "Triennially Plan (3 Years)"
    };
    //get user details
    const GetReseller = async () => {
        let resellerId = Number(id);
        setLoading(true);

        if (!isNaN(resellerId)) {
            try {
                const data = await createRequest(`/api/v1/resellers/get/user`, { id: resellerId });
                if (data?.header?.status === 'success') {
                    setState(data?.body);
                    setReseller(data?.body);
                }
                else {
                    throw new Error(data?.header?.message);
                }

            } catch (error) {
                toast.error(error?.message || "code : rspf-003, Unable to fetch Reseller");
            }
        }

        setLoading(false);
    }
    useEffect(() => {
        GetReseller();
    }, []);


    const saveRequestHandler = async (field) => {

        try {

            if (state[field] === reseller[field] && field !== 'password') {
                throw new Error('Nothing to update');
            }

            if (state[field] === '') {
                throw new Error('Field is empty');
            }

            setLoading(true);
            let token = '';
            if (state.password || field == 'password') {
                if (state['password'] !== state['password_confirmation']) {
                    throw new Error('Password confirmation does not match');
                }
                if (!executeRecaptcha) {
                    return
                }
                token = await executeRecaptcha('register');
            }
            let url = "/api/v1/resellers/update/user";
            let postData = {
                'user_reseller_id': state.id,
                'user_client_id': state.clientId,
                'update': field,
                'value': state[field],
                'id': user_data.id,
                'client_id': user_data.client_id,
                'token': token
            };

            const data = await createRequest(url, postData);

            if (data.header.status === 'success') {

                toast.success(`${field} updated successfully.`);
                setFieldsProps({
                    ...fieldsProps,
                    [field]: false
                })
                GetReseller();
                if (field == 'amountIn' || field == 'amountOut') {
                    state.amountIn = '';
                    state.amountOut = '';
                }
                // dispatch(updateProfile())

            } else {
                throw new Error(data.header.message || "Unable to update, Please try again");
            }


        } catch (error) {

            toast.error(error.message);

        }
        finally {
            if (field === 'password') {
                setModalShow(false);
                setState({
                    ...state,
                    password: '',
                    password_confirmation: ''
                })
            }
            setLoading(false);
        }
    }

    const sendInvoiceHandler = async () => {
        setLoading(true);

        if (state.invoice_amount < 100) {
            toast.error("Invoice Amount must be greater than $100!");
            setLoading(false);
            return;
        }
        if (state.invoice_amount > 10000) {
            toast.error("Invoice Amount must be less than $10,000!");
            setLoading(false);
            return;
        }

        try {
            const data = {
                amount: state.invoice_amount,
                user_reseller_id: state.id,
                user_client_id: state.clientId,
                user_email: state.email,
                id: user_data.id,
                client_id: user_data.client_id,
            };

            const result = await createRequest(`/api/v1/resellers/create/invoice`, data);
            if (result?.header?.status === "success") {
                toast.success("Invoice sent successfully");
                setinvoiceModalShow(false);
            } else {
                throw new Error(result?.header?.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
        setLoading(false);
    };

    const resellerVerificaionHandler = async () => {

        try {
            if (reseller.verified) {
                throw new Error('User Already Verified');
            }
            setLoading(true);
            // let token = '';
            // let url = "/api/v1/resellers/update/user";
            // let postData = {
            //     'user_reseller_id': state.id,
            //     'user_client_id': state.clientId,
            //     'id': user_data.id,
            //     'client_id': user_data.client_id,
            //     'token': token
            // };
            const data = await createRequest(`/api/v1/resellers/verify/user`, { id: state.id });
            // const data = await createRequest(url, postData);

            if (data.header.status === 'success') {

                toast.success(`Reseller Verified successfully.`);

                GetReseller();
            } else {
                throw new Error(data.header.message || "Unable to update, Please try again");
            }
        } catch (error) {
            toast.error(error.message);
        }
        finally {
            setLoading(false);
        }
    }
    const recursivePricingHandler = async (field) => {

        try {
            if (state.recuringPricing[field] === reseller.recuringPricing[field]) {
                throw new Error('Nothing to update');
            }

            if (state.recuringPricing[field] === '') {
                throw new Error('Field is empty');
            }

            setLoading(true);

            let url = "/api/v1/resellers/update/user/pricing";
            let postData = {
                'user_reseller_id': state.id,
                'user_client_id': state.clientId,
                'update': field,
                'recursive':1,
                'value': state.recuringPricing[field],
                'id': user_data.id,
                'client_id': user_data.client_id
            };

            const data = await createRequest(url, postData);

            if (data.header.status === 'success') {

                toast.success(`${field} updated successfully.`);
                setFieldsProps({
                    ...fieldsProps,
                    [field]: false
                })

                // dispatch(updateProfile())

            } else {
                throw new Error(data.header.message || "Unable to update, Please try again");
            }


        } catch (error) {

            toast.error(error.message);

        }
        finally {
            setLoading(false);
        }
    }
    const nonrecursivePricingHandler = async (field) => {

        try {

            if (state.nonRecuringPricing[field] === reseller.recuringPricing[field]) {
                throw new Error('Nothing to update');
            }

            if (state.nonRecuringPricing[field] === '') {
                throw new Error('Field is empty');
            }

            setLoading(true);

            let url = "/api/v1/resellers/update/user/pricing";
            let postData = {
                'user_reseller_id': state.id,
                'user_client_id': state.clientId,
                'update': field,
                'recursive':0,
                'value': state.nonRecuringPricing[field],
                'id': user_data.id,
                'client_id': user_data.client_id
            };

            const data = await createRequest(url, postData);

            if (data.header.status === 'success') {

                toast.success(`${field} updated successfully.`);
                setFieldsProps({
                    ...fieldsProps,
                    [field]: false
                })

                // dispatch(updateProfile())

            } else {
                throw new Error(data.header.message || "Unable to update, Please try again");
            }


        } catch (error) {

            toast.error(error.message);

        }
        finally {
            setLoading(false);
        }
    }

    const listCouponHandler = async (field) => {

    }
    const listVpnHandler = async (field) => {

    }

    const textInputHandler = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const RecuringPricingInputHandler = (e) => {

        setState({
            ...state,
            recuringPricing: { ...state.recuringPricing, [e.target.name]: e.target.value }
        })
    }
    const NonRecuringPricingInputHandler = (e) => {

        setState({
            ...state,
            nonRecuringPricing: { ...state.nonRecuringPricing, [e.target.name]: e.target.value }
        })
    }

    const statusHandler = (e) => {

        setState({

            ...state,
            status: e.target[e.target.selectedIndex].value

        });

    }

    const closeEditable = (e) => {

        const label = e.target.parentElement.parentElement.parentElement.querySelector('label').attributes['data-label'].value;
        setFieldsProps({
            ...fieldsProps,
            [label]: false
        })
    }


    const editField = (e) => {
        const parent = e.target.parentElement.parentElement;
        const label = parent.querySelector('label').attributes['data-label'].value;

        switch (label) {
            case "password":
                setFieldsProps({
                    ...fieldsProps,
                    password: true
                })

                break;
            case "status":
                setFieldsProps({
                    ...fieldsProps,
                    status: true
                })
                parent.classList.add('editable');
                parent.querySelector('select').disabled = false;
                parent.querySelector('select').removeAttribute('readOnly')
                break;
            default:
                setFieldsProps({
                    ...fieldsProps,
                    [label]: true
                })
                break;
        }
    }

    return (
        <>
            {/* {viewInvoiceId !== null && viewInvoiceId !== 0 ? <Redirect to={`/${userslug}/user/edit/${viewInvoiceId.toString()}`} /> : null} */}
            <h2 className="component-heading">Profile</h2>
            {loading ? <Loader /> :

                <div className="w-box">
                    <form className="edit-profile-details" autoComplete="off">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Name</label><label>{reseller.firstName}</label>
                                </div>
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Email</label><label>{reseller.email}</label>
                                </div>
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Sign up Date</label><label>{reseller.created_at}</label>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Balance</label><label>{reseller.balance}</label>
                                </div>
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Active Subscriptions</label><label>{reseller.active_subscriptions}</label>
                                </div>
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">VPN Acounts Issued last 24Hours</label><label>{reseller.vpn_accounts_issued_in_last_24_hours}</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Total Coupons</label><label>{reseller.total_coupons}</label>
                                </div>
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Coupons In last 24Hours</label><label>{reseller.coupons_in_last_24_hours}</label>
                                </div>
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="name">Total Redeem Coupons</label><label>{reseller.total_redeem_coupons}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <AdminRoute to={`/user/edit/${state.id}/generatecoupon`} exact={true} className="w-button">
                                        Generate Coupon
                                    </AdminRoute>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <AdminRoute to={`/user/edit/${state.id}/listcoupons`} exact={true} className="w-button">
                                        View All Coupons
                                    </AdminRoute>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <AdminRoute to={`/user/edit/${state.id}/listsusbscriptions`} exact={true} className="w-button">
                                        View All VPN users
                                    </AdminRoute>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <Button className="theme-gradient-btn" onClick={() => setinvoiceModalShow(true)}>
                                        Send Invoice
                                    </Button>

                                    <SendInvoiceToResellerModal
                                        show={invoiceModalShow} textInputHandler={textInputHandler} state={state}
                                        onHide={() => setinvoiceModalShow(false)} saveFunc={sendInvoiceHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="email">Email / Username</label>
                                    <input type="text" id="profile_email" onChange={(e) => { textInputHandler(e) }} name="email" className="form-control" value={state.email} autoComplete="off" readOnly={!reseller.email ? 'readOnly' : ''} />

                                    {fieldsProps.email ?
                                        <EditableDiv field={"email"} closeFunc={closeEditable} saveFunc={saveRequestHandler} />
                                        : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" style={{ position: "relative" }}>
                                    <label data-label="password">Password</label>
                                    <Button className="theme-gradient-btn" onClick={() => setModalShow(true)}>
                                        Change Password
                                    </Button>

                                    <MyVerticallyCenteredModal
                                        show={modalShow} textInputHandler={textInputHandler} state={state}
                                        onHide={() => setModalShow(false)} saveFunc={saveRequestHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        {

                            reseller.verified ? <>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group" style={{ position: "relative" }}>
                                            <label data-label="status">Status</label>
                                            <select id="profile_status" name="status" className="form-control" style={styleCSS.disableSelectCSS} value={state.status} onChange={statusHandler} readOnly={!fieldsProps.status ? 'readOnly' : ''} disabled={!fieldsProps.status ? 'disabled' : ''} >
                                                <option value={0}>Inactive</option>
                                                <option value={1}>Active</option>
                                            </select>

                                            {fieldsProps.status ?
                                                <EditableDiv field={"status"} closeFunc={closeEditable} saveFunc={saveRequestHandler} />
                                                : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group" style={{ position: "relative" }}>
                                            <label data-label="amountIn">Amount In</label>
                                            <input type="text" id="profile_email" onChange={(e) => { textInputHandler(e) }} name="amountIn" className="form-control" value={state.amountIn} autoComplete="off" readOnly={!fieldsProps.amountIn ? 'readOnly' : ''} />

                                            {fieldsProps.amountIn ?
                                                <EditableDiv field={"amountIn"} closeFunc={closeEditable} saveFunc={saveRequestHandler} />
                                                : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group" style={{ position: "relative" }}>
                                            <label data-label="amountOut">Amount Out</label>
                                            <input type="text" id="profile_email" onChange={(e) => { textInputHandler(e) }} name="amountOut" className="form-control" value={state.amountOut} autoComplete="off" readOnly={!fieldsProps.amountOut ? 'readOnly' : ''} />

                                            {fieldsProps.amountOut ?
                                                <EditableDiv field={"amountOut"} closeFunc={closeEditable} saveFunc={saveRequestHandler} />
                                                : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group" style={{ position: "relative" }}>
                                            <h4>Product Pricing</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group" style={{ position: "relative" }}>
                                            <h4>Recursive</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    {Object.keys(state?.recuringPricing || {}).map((key) => {

                                        if (billingCycleNames[key]) {
                                            return (
                                                <div className="col-md-4" key={key}>
                                                    <div className="form-group" style={{ position: "relative" }}>
                                                        <label data-label={'rec'+key}>{billingCycleNames[key]}</label>
                                                        <input type="text" className="form-control" onChange={RecuringPricingInputHandler} name={key} value={state.recuringPricing[key]} />

                                                        {fieldsProps['rec'+key] ?
                                                            <EditableDiv field={key} closeFunc={closeEditable} saveFunc={recursivePricingHandler} />
                                                            : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}

                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group" style={{ position: "relative" }}>
                                            <h4>Non Recursive</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {Object.keys(state?.nonRecuringPricing || {}).map((key) => {

                                        if (billingCycleNames[key]) {
                                            return (
                                                <div className="col-md-4" key={key}>
                                                    <div className="form-group" style={{ position: "relative" }}>
                                                        <label data-label={key}>{billingCycleNames[key]}</label>
                                                        <input type="text" className="form-control" onChange={NonRecuringPricingInputHandler} name={key} value={state.nonRecuringPricing[key]} />

                                                        {fieldsProps[key] ?
                                                            <EditableDiv field={key} closeFunc={closeEditable} saveFunc={nonrecursivePricingHandler} />
                                                            : <FontAwesomeIcon style={styleCSS.faEyeStyle} icon={faPencilAlt} onClick={editField} />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}

                                </div>
                            </> :
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group" style={{ position: "relative" }}>
                                            <label data-label="password">Unverified User</label>
                                            <Button className="theme-gradient-btn" onClick={resellerVerificaionHandler}>
                                                Verify this user
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                        }
                    </form>
                </div>
            }
        </>
    )
}

export default Profile;