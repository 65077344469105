import { createRequest } from "../../utils/common";
import { UPDATE_NOTIFICATION } from "./types";

export const NewNotification = () => async (dispatch) => {
  try {
    const data = await createRequest("/api/v1/resellers/notifications/unread");

    if (data?.header?.status === "success") {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: data.body,
      });
    } else {
      throw new Error(
        data?.header?.message || "code : rspf-update-profile-1022"
      );
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
