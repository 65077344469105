import React from 'react';

const Loader = ({loading}) => {
    return(
        <div className={'dashboard-inner-loader ml-0 ' + (loading ? 'show' : 'hide')}>
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    )
};
export default Loader