import React, {Fragment, useState} from "react";
import { createRequest } from "../../../../../utils/common";
import PAYPAL_IMG from '../../../../../assets/img/paypal_logo_png.png'

const CheckoutForm = ({ checkAmount, error, user, isValid, amount, toast, setLoading, swal }) => {

    const amountWithCurrency = amount !== ''? `$${amount}` : '';

    const [form, setForm] = useState(null);

    const ppButtonStyle = {
        background: "#ffc439",
        border: 0,
        borderRadius: "6px",
        padding: "10px 20px"
    }

    const handleSubmit = async (event) => {

        event.preventDefault();
        checkAmount(amount)
        
        if(!isValid){
  
          toast.error(error === "" ? "Please enter valid amount" : error);
          return;
  
        }
  
        try {

            setLoading(true);

            const params = { name:user.name, email:user.email, amount }

            const result = await createRequest('/api/v1/payments/paypal', params).catch((e) => {
                throw new Error(e?.message)
            });
  
            if(result?.header?.code === 400002 && result?.header?.status === 'success'){
              
                setForm(result?.body);
                document.getElementById("gatewayForm").children[0].submit();
  
            }else{
  
                throw new Error(result?.header?.message)
  
            }
            
        } catch (error) {

            
            toast.error(error?.message);
            setLoading(false);
            
        }
        
      };
  
    return (
        <Fragment>

            <button onClick={(e) => handleSubmit(e)} style={ppButtonStyle} type="button" className="">Pay {amountWithCurrency} with 
                <img style={{width:"56px",marginLeft:"10px"}} src={PAYPAL_IMG} alt="PayPal" />
            </button>
            {form ? <div id="gatewayForm" style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: form }}></div> : ""}
            

        </Fragment>
    );
};


export default CheckoutForm