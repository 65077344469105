import moment from "moment";
import { useHistory } from "react-router-dom";

const NotifyDrop = ({ typename, message, date, redirect, Id }) => {
  let history = useHistory();
  const dateTime = date.split(" ");
  const md = moment.utc(`${dateTime[0]}T${dateTime[1]}`);
  const fCharData = typename.split(" ");
  var x = fCharData[0];
  var y = fCharData[1];
  let fChar = `${x.charAt(0)}${y.charAt(0)}`;

  const redirectNotification = () => {
    if (redirect == true) {
      history.push(`/${typename}/${Id}`);
    }
  };

  return (
    <>
      <div
        className="notifications-item"
        onClick={() => redirectNotification()}
      >
        {" "}
        <div className="text">
          <div className="topbar__collapse-img-wrap">{fChar}</div>
          <div className="notifity-content w-100">
            <div className="d-flex justify-content-between w-100">
              <div className="notify-drop-title">
                <p>{typename}</p>
              </div>
              <div>
                <p>
                  <span>{md.fromNow()}</span>
                </p>
              </div>
            </div>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotifyDrop;
