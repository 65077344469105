import React, {useState, useEffect, Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Loader} from "../../Loader";
import { updateProfile } from '../../../redux/actions/auth';


const SuccessPayment = () => {

    const loading = false;
    const [isPaid, setIsPaid] = useState(false);
    const [text, setText] = useState('Your payment is being processed… Please Wait');
    const dispatch = useDispatch();
    const refreshBalance = () => {
        dispatch(
            updateProfile()
        )
    }

    useEffect(() => {
        let count = 0;
        const intrvl = setInterval(() => {
            if (count === 3000) {
                setText('Verifying Your Payment…');
            }
            if (count === 6000) {
                refreshBalance();
                setTimeout(() => {
                    setText('Your Payment has been confirmed and successfully credited to your wallet.');
                }, 500);
            }
            if (count === 7000) {
                setText('Redirecting you to the Dashboard…');
                count = 0;
                setTimeout(() => {
                    setIsPaid(true);
                }, 1000);
                clearInterval(intrvl);
            }

            count++;
        }, 1);

    }, []);

    return (
        <Fragment>
            {isPaid ? <Redirect to="/" /> : ''}
            {loading ? <Loader/> : 
            <div className="text-center">
                <div className="row">   
                    <div className="col-md-12">
                        <FontAwesomeIcon title="Success" icon={faCheckCircle} size={"4x"} className="mr-2, fa-flip" style={{cursor:"pointer"}} color={"#28a745"} />
                        <p></p>
                        <h1>Thank You</h1>
                        <Loader/>
                        <p></p>
                        <h5>{text}</h5>
                        <p></p>
                        <p style={{color:"#a8a8a8", fontSize:"14px"}} >It may take 1 to 2 minute(s) to verify your payment. </p>
                    </div>
                </div>
            </div>}
            
        </Fragment>
    );

}

export default SuccessPayment;