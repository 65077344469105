const TransactionDetails = ({ val }) => {
  return (
    <>
      <table className="tdetails-table">
        <tr>
          <th>ID</th>
          <td>{val.id}</td>
        </tr>
        <tr>
          <th>DATE</th>
          <td>{val.date}</td>
        </tr>
        <tr>
          <th>TRANSACTION ID</th>
          <td>{val.transaction_id}</td>
        </tr>
        <tr>
          <th>PAYMENT METHOD</th>
          <td>{val.gateway}</td>
        </tr>
        <tr>
          <th>DESCRIPTION</th>
          <td>{val.description}</td>
        </tr>
        <tr>
          <th>AMOUNTIN</th>
          <td>{val.amount_in}</td>
        </tr>
        <tr>
          <th>AMOUNTOUT</th>
          <td>{val.amount_out}</td>
        </tr>
      </table>
    </>
  );
};

export default TransactionDetails;
