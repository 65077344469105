import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { createRequest } from "../../utils/common";
import { NewNotification } from "../../redux/actions/notification";
import { useDispatch } from "react-redux";

const NotificationsCard = ({
  typename,
  message,
  date,
  readAt,
  redirect,
  Id,
  notificationid,
}) => {
  const [instentread, setInstentread] = useState(true);
  let history = useHistory();
  const dateTime = date.split(" ");
  const md = moment.utc(`${dateTime[0]}T${dateTime[1]}`);

  const dispatch = useDispatch();

  const redirectNotification = async (notificationid) => {
    setInstentread(false);
    if (redirect == true) {
      history.push(`/${typename}/${Id}`);
    }

    await createRequest("/api/v1/resellers/notifications/markread", {
      method: "POST",
      body: notificationid,
    });

    dispatch(NewNotification());
  };

  return (
    <>
      <div
        className="dashboard-wrapper notifications-wrap-head"
        onClick={() => redirectNotification(notificationid)}
      >
        <div
          style={
            !readAt && instentread
              ? { background: "#f5fcff", width: "102%" }
              : {}
          }
          className="dashboard-banner notification-wrap"
          style={
            !readAt && instentread
              ? {
                  borderLeft: "4px solid  #0896ae",
                  background: "#f5fcff",
                  width: "102%",
                }
              : {}
          }
        >
          <div className="head-line-notify">
            <div>
              <h6>{typename}</h6>
            </div>
            <div>
              <p className="notification-time">{md.fromNow()}</p>
            </div>
          </div>

          <p className="notification-content">{message}</p>
        </div>
      </div>
    </>
  );
};

export default NotificationsCard;
