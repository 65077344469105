import React, { Fragment, useEffect, useState, useRef } from "react";
import { createRequest } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { CustomDivTable } from "../CustomDivTable";
import { Loader } from "../Loader";
import { Redirect } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";

const CouponList = ({ toast }) => {
  const [viewInvoiceId, setViewInvoiceId] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState("10");
  const userslug = useSelector((state) => state?.auth?.user?.custom_url);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [filterType, setFilterType] = useState("email"); //code, date, status
  const [reload, setReload] = useState(null);
  const [filterData, setFilterData] = useState({
    email: "",
  });

  const [state, setState] = useState({
    coupon_list: [],
    coupons_data: {},
    coupons_total: 0,
  });
  const greyBorder = {
    border: "1px solid #cecece",
  };

  const elements = {
    email: (
      <input
        type="text"
        className="form-control"
        placeholder="Email"
        onChange={(e) =>
          setFilterData({
            email: e.target.value,
            status: null,
          })
        }
        value={filterData?.email}
      />
    )
  };


  const GetResellerList = async () => {
    
    setLoading(true);

    try {
      let filterPayload = { ...filterData };
      const data = await createRequest(
        `/api/v1/resellers/get/users?page_limit=${pageSize}&page=${currentPage}`,
        filterPayload
      );

      if (data?.header?.status === "success") {
        setState({
          ...state,
          coupon_list: data?.body?.data,
          coupons_data: data?.body,
          coupons_total: data?.body?.total,
        });
      } else {
        throw new Error(data?.header?.message);
      }
    } catch (error) {
      toast.error(
        error?.message || "code : rspf-003, Unable to fetch coupon list"
      );
      setState({
        ...state,
        coupon_list: [],
        coupons_data: [],
        coupons_total: 0,
      });
    }finally{
      setLoading(false);
      setReload(false)
    }
    
  };

  const SwitchPageSize = (e) => {
    let records_per_page = e.target.value;
    setCurrentPage(1);
    setPageSize(records_per_page);
  };

  const _callback_setCurrentPage = (pageno) => {
    setFilterEnabled(false);
    setCurrentPage(pageno);
  };

  const _callback_setFilterEnabled = async (e) => {
    if (
      filterData.status === "" &&
      filterData.email === "" &&
      filterData.dateStart === "" &&
      filterData.dateEnd === ""
    ) {
      setFilterEnabled(false);
    } else {
      setFilterEnabled(false);
      setFilterType("email");
      setFilterData({
        email: "",
        dateStart: "",
        dateEnd: "",
        status: "",
      });
      setReload(true);
    }
  };
  const ViewInvoice = async (e) => {
    var pEle = e.target.parentElement.parentElement;
    if (e.target.nodeName !== 'BUTTON') {
        pEle = e.target.parentElement.parentElement.parentElement;
    }
    if (e.target.nodeName === 'path') {
        pEle = e.target.parentElement.parentElement.parentElement.parentElement;
    }
    let invoiceId = pEle.children[0].innerText;
    setViewInvoiceId(Number(invoiceId));
}

  useEffect(() => {
    GetResellerList();
  }, [pageSize, currentPage]);

  return (
    <>
    {viewInvoiceId !== null && viewInvoiceId !== 0 ? <Redirect to={`/${userslug}/user/edit/${viewInvoiceId.toString()}`} /> : null}
    <div className="w-box wp-box">
      <h2 className="component-heading mb-0">Resellers List</h2>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {/* {state?.coupon_list?.length > 0 ? */}
          <div className="row records-switcher mt-4">
            {state?.coupon_list?.length > 0 ? (
              <div className="col-md-4 d-flex flex-column justify-content-end">
                <div className="form-group m-0">
                  <label className="m-0">
                    Show
                    <select
                      id="records_per_page"
                      className="form-control"
                      onChange={SwitchPageSize}
                      value={pageSize}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                    per page
                  </label>
                </div>
              </div>
            ) : (
              <div className="col-md-4"></div>
            )}

            {/* <div className="col-md-8 text-right d-flex justify-content-end filter-mblwrap"> */}
            <div
              className={`col-md-8 text-right d-flex justify-content-end ${
                filterEnabled && "filter-mblwrap"
              }`}
            >
              <div
                className={`form-group m-0 mr-2 ${
                  filterEnabled && "filter-mbl"
                }`}
              >
                {filterEnabled ? (
                  <>
                    <button
                      className="btn mw-auto mr-2"
                      style={{
                        padding: "5px 10px",
                        fontSize: "14px",
                        width: "30.99px",
                      }}
                      title="Clear Filter"
                      onClick={_callback_setFilterEnabled}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>                   
                  </>
                ) : (
                  <button
                    className="theme-gradient-btn mw-auto"
                    style={{ padding: "5px 10px", fontSize: "14px" }}
                    onClick={() => setFilterEnabled(true)}
                  >
                    Filter
                  </button>
                )}
                <div className="search-field d-inline-block">
                  {filterEnabled ? elements[filterType] : ""}
                </div>
                {filterEnabled ? (
                  <button
                    className="theme-gradient-btn mw-auto ml-2"
                    style={{
                      padding: "5px 10px",
                      fontSize: "14px",
                      width: "30.99px",
                    }}
                    title="Search"
                    onClick={GetResellerList}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                ) : (
                  ""
                )}
              </div>

            </div>
          </div>



          <CustomDivTable
            props={state?.coupon_list ? state?.coupon_list : []}
            id="coupon-list-table"
            classes={["mt-3"]}

            settings={{
              actionColumn: {
                enable: true,
                rules: {
                  all: {
                    el: <button onClick={ViewInvoice} className="ct_btn"><FontAwesomeIcon icon={faEye} /></button>
                  },
                },
              },
              row_identifier: "Coupon_ID",
              date_cols: ["generated_at"],
            }}
          />

          {state?.coupon_list?.length ? (
            <div className="pagination-container">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(pageSize)}
                totalItemsCount={state.coupons_data.total}
                pageRangeDisplayed={5}
                prevPageText={<span>‹</span>}
                nextPageText={<span>›</span>}
                firstPageText={<span>«</span>}
                lastPageText={<span>»</span>}
                onChange={_callback_setCurrentPage}
              />
            </div>
          ) : (
            ""
          )}
        </Fragment>
      )}
    </div>
    </>
  );
};

export default CouponList;
