import { Fragment } from "react";

const TableBtn = ({props, children}) => {
    return(
        props ? 
        <button  onClick={props} className="ct_btn">
            <i className="fa fa-trash" aria-hidden="true"></i>
            {children}
        </button> : 
        <button className="ct_btn" disabled>
            <i className="fa fa-trash" aria-hidden="true"></i>
            {children}
        </button>
    )
}

export const CustomDivTable = ({ props, settings, id, classes = [] }) => {
  if (props?.length === 0) {
    return (
      <div className="custom_div_table have-error">
        <div className="no-data">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i> No
          Records Found!
        </div>
      </div>
    );
  }

    const columnHeader = Object.keys(props[0]);
    const { row_identifier, date_cols = [] } = settings;
    const lastColumn = {
        title: 'Action',
        enable: settings?.actionColumn?.enable || false,
        rules: settings?.actionColumn?.rules
    }

  return (
    <Fragment>
      <div className={`custom_div_table ` + classes.join(" ")} id={id}>
        <div className="c_table_head">
          <div className="c_row">
            {columnHeader.map((h, i) => {
              return h === row_identifier ? (
                ""
              ) : (
                <div key={i} className="c_data">
                  {h.replace("_", " ")}
                </div>
              );
            })}
            {lastColumn?.enable ? (
              <div className="c_data">{lastColumn?.title}</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="c_table_body">
                {props.map((obj, i) => {
                    var row = Object.values(obj);
                    var cArr = [];

                    return(
                        <div key={i} className="c_row" data-identifier={obj[row_identifier]}>
                            {row.map((value, index) => {
                                
                                if (obj[row_identifier] === value) {
                                    return ('');
                                }

                                if (lastColumn?.enable) {
                                    if (Object.keys(lastColumn.rules).includes(Object.keys(obj)[index].toLowerCase())) {
                                        cArr.push({
                                            [Object.keys(obj)[index].toLowerCase()]: value
                                        });
                                    }
                                }

                                return(
                                    <div
                                        key={index} 
                                        className="c_data" 
                                        data-column={Object.keys(obj)[index]}>
                                            {date_cols.indexOf(Object.keys(obj)[index].toLowerCase()) > -1 ? 
                                                new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString() 
                                                : value}
                                        </div>
                                )
                            })}
                            {lastColumn?.enable ?
                                <div className="c_data haveBtn">
                                    {Object.keys(lastColumn.rules).map((r, i) => {
                                        return (
                                            r === 'all' ? '' : 
                                                <Fragment key={i}>
                                                    {lastColumn.rules[r]?.conditions[cArr.filter(c => Object.keys(c)[0] === r ? c : '')[0][r].toLowerCase()]}
                                                </Fragment>
                                        )
                                    })}
                                    {lastColumn.rules['all']?.el}
                                </div>
                            : ''}
                        </div>
                    )
                })}
        </div>
      </div>
    </Fragment>
  );
};
